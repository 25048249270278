import { defineComponent, computed, ref, watch } from 'vue';
import Multiselect from '@vueform/multiselect';
export default defineComponent({
    // TODO: 임시 명칭. 10월 말 고도화 예정
    name: 'MultiCheckSelector',
    components: {
        Multiselect,
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: 'Select an option',
        },
        isSelectAll: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            default: () => [],
        },
        modelValue: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['update:modelValue', 'close'],
    setup(props, { emit }) {
        const maxbySelectedOption = computed(() => (props.isSelectAll ? 1 : -1));
        const isShowOptions = computed(() => !props.isSelectAll);
        const selectOptions = computed(() => props.options);
        const isCloseOptions = ref(true);
        const inputValue = ref(props.modelValue);
        const onOpen = () => {
            isCloseOptions.value = false;
        };
        const onClose = () => {
            isCloseOptions.value = true;
            emit('close');
        };
        watch(() => props.modelValue, () => {
            if (inputValue.value !== props.modelValue) {
                inputValue.value = props.modelValue || [];
            }
        });
        watch(() => inputValue.value, () => {
            emit('update:modelValue', inputValue.value, isCloseOptions.value);
        });
        return {
            selectOptions,
            inputValue,
            maxbySelectedOption,
            isShowOptions,
            onOpen,
            onClose,
        };
    },
});
