/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import { createStore, createLogger } from 'vuex';
import { Helper } from '@hems/util';
import appCtx from './modules/appCtx';
import user from './modules/user';
export default createStore({
    plugins: Helper.getAppEnv() === 'local' ? [createLogger()] : [],
    modules: { appCtx, user },
});
