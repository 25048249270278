import { SAPN_TYPE, FORM_TYPE, GROUP_MODE, ENERGY_POLICY } from '@hems/util/src/constant/constant';
import { AC_DEVICE_TYPE, GEN_TYPE } from '@hems/util/src/constant/device';
import { GRAPH_GROUP_CODE } from '@hems/util/src/constant/graph';
import { isEmptyString } from '@hems/util/src/helper/helper';
export function isStringType(value) {
    return typeof value === 'string';
}
export function isSAPNType(value) {
    return value === SAPN_TYPE.SAPN || value === SAPN_TYPE.FAIL || value === SAPN_TYPE.NO;
}
export function isYesOrNoType(value) {
    return value === 'Y' || value === 'N';
}
export function isFormModeType(value) {
    return value === FORM_TYPE.READ || value === FORM_TYPE.EDIT || value === FORM_TYPE.NEW;
}
export function isGroupModeType(value) {
    return value === GROUP_MODE.READ || value === GROUP_MODE.NEW_COPY || value === GROUP_MODE.NEW;
}
export function isGenType(value) {
    return (value === GEN_TYPE.GEN2 ||
        value === GEN_TYPE.GEN3 ||
        value === GEN_TYPE.FOX_ESS ||
        value === GEN_TYPE.FOX_ESS_H3 ||
        value === GEN_TYPE.AC_SYS);
}
export const getRouteInfo = (value, isTypeGuardValue) => {
    if (isTypeGuardValue(value))
        return value;
    return;
};
export const isPeriod = (date) => {
    return 'start' in date && 'end' in date;
};
export const isNumberType = (value) => {
    return typeof value === 'number';
};
export const getStringValue = (value) => {
    if (value === undefined || value === null || isEmptyString(value.trim())) {
        return null;
    }
    return value;
};
export const getYesOrNoValue = (value) => {
    return isYesOrNoType(value) ? value : 'Y';
};
export const isDeviceACType = (device) => {
    return device.genType === GEN_TYPE.AC_SYS;
};
export const isACSystemType = (deviceInfo) => {
    return deviceInfo.gen_type === GEN_TYPE.AC_SYS;
};
export const getNumberValue = (value) => {
    if (value === undefined || value === null) {
        return null;
    }
    return value;
};
export const isACDeviceType = (value) => {
    const ACDeviceTypeValueList = Object.values(AC_DEVICE_TYPE);
    return ACDeviceTypeValueList.includes(value);
};
export const getACDeviceTypeValue = (value) => {
    if (value === undefined || value === null) {
        return null;
    }
    if (isACDeviceType(value)) {
        return value;
    }
    return null;
};
export const isGraphGroupCodeType = (value) => {
    const graphGroupCodeList = Object.values(GRAPH_GROUP_CODE);
    return graphGroupCodeList.includes(value);
};
export const getGraphGroupCodeTypeValue = (value) => {
    if (isGraphGroupCodeType(value)) {
        return value;
    }
    return null;
};
export const isGraphGroupCodeListType = (value) => {
    const graphGroupCodeList = Object.values(GRAPH_GROUP_CODE);
    return value.every((item) => graphGroupCodeList.includes(item));
};
export const getGraphGroupCodeListTypeValue = (value) => {
    if (isGraphGroupCodeListType(value)) {
        return value;
    }
    return null;
};
export const isListSize = (value) => [10, 20, 50].includes(value);
export const getListSize = (value) => {
    if (isListSize(value))
        return value;
};
export const isSortColumnValue = (value, validSortColumns) => {
    const validSortOrders = ['asc', 'desc'];
    const match = value.match(/^(\w+)\s+(asc|desc)$/);
    if (match) {
        const column = match[1];
        const order = match[2];
        if (validSortColumns.includes(column) && validSortOrders.includes(order)) {
            return true;
        }
    }
    return false;
};
export const getSortColumn = (value, validSortColumns) => {
    if (isSortColumnValue(value, validSortColumns)) {
        return value;
    }
    return;
};
export const isPvStringPowerValue = (value) => value >= 1 && value <= 5;
export const isRowKeyType = (rowKey) => typeof rowKey === 'number' || typeof rowKey === 'string';
export const isErrorCodeDisplayDetails = (errorDetails) => errorDetails !== null && typeof errorDetails === 'object' && 'description' in errorDetails;
export const isDisplayedSiteErrorInfoType = (value) => Array.isArray(value) &&
    value.every((item) => typeof item === 'object' && item && 'errorCode' in item && 'triggerDate' in item);
export const isEnergyPolicyType = (operationMode) => {
    const energyPoliciesValues = Object.values(ENERGY_POLICY);
    return energyPoliciesValues.includes(operationMode);
};
