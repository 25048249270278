import { ACSystemModelNamePrefixes, AC_DEVICE_TYPE, CONSUMPTION_CT_TYPE, EMS_PRIORITY_ORDER_MAP, GEN_TYPE, } from '@hems/util/src/constant';
export function isSmartModule(data) {
    if (isSmartModuleGen1(data) || isSmartModuleGen2(data) || isSmartModuleACSystem(data))
        return true;
    return false;
}
export function isPvModule(data) {
    if (isPvModuleGen2(data) || isPvModuleGen3(data) || isPvModuleFox(data))
        return true;
    return false;
}
export function isPvModuleGen2(data) {
    if (data.gen_type === 'GEN2')
        return true;
    return false;
}
export function isPvModuleGen3(data) {
    if (data.gen_type === 'GEN3')
        return true;
    return false;
}
export function isPvModuleFox(data) {
    if (data.gen_type === 'FOX_ESS' || data.gen_type === 'FOX_ESS_H3')
        return true;
    return false;
}
export function isSmartModuleGen1(data) {
    if (data.gen_type?.startsWith('MLPE_GEN1'))
        return true;
    return false;
}
export function isSmartModuleGen2(data) {
    if (data.gen_type?.startsWith('DC_OPTIMIZER_GEN2'))
        return true;
    return false;
}
export function isSmartModuleACSystem(data) {
    if (data.gen_type?.startsWith('AC_SYS'))
        return true;
    return false;
}
export function getACSystemType(deviceInfo, siteConfiguration) {
    // Multi
    if (deviceInfo.length >= 2) {
        if (siteConfiguration) {
            const { hasPV, hasESS, hasHub, hasThirdParty } = siteConfiguration;
            if (hasThirdParty) {
                if (hasHub) {
                    const essList = deviceInfo.filter((item) => item.device_type_cd === AC_DEVICE_TYPE.AC_ESS);
                    if (essList.length >= 2)
                        return AC_DEVICE_TYPE.AC_THIRD_PARTY_MULTI_ESS_HUB;
                    return AC_DEVICE_TYPE.AC_THIRD_PARTY_ESS_HUB;
                }
                return AC_DEVICE_TYPE.AC_THIRD_PARTY_ESS;
            }
            if (hasPV && hasESS && hasHub)
                return AC_DEVICE_TYPE.AC_COMBINER_MULTI_ESS_HUB;
            if (hasPV && hasESS)
                return AC_DEVICE_TYPE.AC_COMBINER_MULTI_ESS;
            if (hasESS)
                return AC_DEVICE_TYPE.AC_MULTI_ESS;
        }
        return AC_DEVICE_TYPE.AC_COMBINER_MULTI_ESS_HUB;
    }
    // Single
    // 'AC_COMB' (case 1) or 'AC_ESS' (case 8-1)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return deviceInfo[0].device_type_cd;
}
export const isACSystemModel = (productModelName) => ACSystemModelNamePrefixes.some((modelNamePrefix) => productModelName.startsWith(modelNamePrefix));
export const deviceInfoSorter = (deviceA, deviceB) => {
    if (!deviceA.deviceInfo.ems_type_cd || !deviceB.deviceInfo.ems_type_cd)
        return 0;
    const priorityDeviceA = EMS_PRIORITY_ORDER_MAP[deviceA.deviceInfo.ems_type_cd] ?? Number.MAX_SAFE_INTEGER;
    const priorityDeviceB = EMS_PRIORITY_ORDER_MAP[deviceB.deviceInfo.ems_type_cd] ?? Number.MAX_SAFE_INTEGER;
    return priorityDeviceA - priorityDeviceB;
};
export const isLoadSideConsumptionCT = (deviceInfo) => deviceInfo.gen_type === GEN_TYPE.AC_SYS &&
    deviceInfo.accb_consumption_ct_installed_point === CONSUMPTION_CT_TYPE.LOAD_SIDE;
export const isGridSideConsumptionCT = (deviceInfo) => deviceInfo.gen_type === GEN_TYPE.AC_SYS &&
    deviceInfo.accb_consumption_ct_installed_point === CONSUMPTION_CT_TYPE.GRID_SIDE;
export const isNotInstalledConsumptionCT = (deviceInfo) => deviceInfo.gen_type === GEN_TYPE.AC_SYS &&
    deviceInfo.accb_consumption_ct_installed_point === CONSUMPTION_CT_TYPE.NOT_INSTALLED;
