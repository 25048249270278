import { defineComponent, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Calendar from '@toast-ui/calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import { BACKGROUND_COLOR, BASIC_GRAY_COLOR, STYLE_NONE, COLOR_OPACITY, FONT_COLOR, PRIMARY_COLOR, SUB_COLOR, STYLE_INHERIT, } from '@hems/util/src/constant';
import { SCHEDULE_EVENT_TYPE, CALENDAR_ID } from '@hems/util/src/constant/energyManagement';
import { isEmptyArray } from '@hems/util/src/helper/helper';
export default defineComponent({
    name: 'WeeklyScheduler',
    props: {
        scheduleEventList: {
            type: Array,
            default: () => [],
        },
    },
    setup(props) {
        const { t } = useI18n();
        const weeklySchedulerContainer = ref('');
        const weeklyScheduler = ref(null);
        // FIXME: toast-ui/calendar의 Theme 타입에 오류가 있어서 import하여 사용 불가 -> 대체 방안 찾기
        const weeklySchedulerTheme = {
            common: {
                backgroundColor: BACKGROUND_COLOR.LIGHT_MODE_BG2,
                border: `1px solid ${BASIC_GRAY_COLOR.GRAY_100}`,
                dayName: {
                    color: FONT_COLOR.GRAY,
                },
                holiday: {
                    color: FONT_COLOR.GRAY,
                },
                saturday: {
                    color: FONT_COLOR.GRAY,
                },
                today: {
                    color: FONT_COLOR.GRAY,
                },
            },
            week: {
                dayName: {
                    backgroundColor: BACKGROUND_COLOR.LIGHT_MODE_BG1,
                    borderBottom: STYLE_NONE,
                    borderTop: STYLE_NONE,
                },
                pastTime: {
                    color: FONT_COLOR.GRAY,
                },
                futureTime: {
                    color: FONT_COLOR.GRAY,
                },
                pastDay: {
                    color: FONT_COLOR.GRAY,
                },
                today: {
                    backgroundColor: STYLE_INHERIT,
                },
                timeGrid: {
                    borderRight: `1px solid ${BASIC_GRAY_COLOR.GRAY_100}`,
                },
                timeGridHourLine: {
                    borderBottom: `1px solid ${BASIC_GRAY_COLOR.GRAY_100}`,
                },
            },
        };
        const getDisplayedTimeString = (time) => `${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}`;
        const weeklySchedulerOptions = {
            defaultView: 'week',
            usageStatistics: false,
            calendars: [
                {
                    id: CALENDAR_ID,
                    name: CALENDAR_ID,
                    backgroundColor: `${PRIMARY_COLOR.P2}${COLOR_OPACITY[30]}`,
                    borderColor: 'transparent',
                },
            ],
            week: {
                taskView: false,
                eventView: ['time'],
                dayNames: [
                    t('common.sun'),
                    t('common.mon'),
                    t('common.tue'),
                    t('common.wed'),
                    t('common.thu'),
                    t('common.fri'),
                    t('common.sat'),
                ],
                showNowIndicator: false,
            },
            gridSelection: false,
            theme: weeklySchedulerTheme,
            template: {
                time: () => {
                    return '';
                },
                weekDayName: ({ dayName }) => {
                    return `<div class="weekly-scheduler-day-name">${dayName}</div>`;
                },
                timegridDisplayPrimaryTime: ({ time }) => {
                    return `<span class="weekly-scheduler-time">${getDisplayedTimeString(time)}</span>`;
                },
            },
        };
        const createScheduler = () => {
            weeklyScheduler.value = new Calendar(weeklySchedulerContainer.value, weeklySchedulerOptions);
        };
        const scheduleEventDefaultStyle = {
            width: '100%',
            left: 0,
            marginLeft: 0,
            borderRadius: '4px',
            cursor: 'default',
        };
        const duplicatedScheduleEventStyle = {
            ...scheduleEventDefaultStyle,
            zIndex: 1,
            backgroundColor: `${BACKGROUND_COLOR.LIGHT_MODE_BG1}${COLOR_OPACITY[60]}`,
            border: `2px solid ${SUB_COLOR.RED}`,
        };
        const highLightScheduleEventStyle = {
            ...scheduleEventDefaultStyle,
            backgroundColor: `${PRIMARY_COLOR.P2}${COLOR_OPACITY[50]}`,
            border: `2px solid ${PRIMARY_COLOR.P2}`,
        };
        const temporaryScheduleEventStyle = {
            ...scheduleEventDefaultStyle,
            zIndex: 1,
            backgroundColor: `${BACKGROUND_COLOR.LIGHT_MODE_BG1}${COLOR_OPACITY[60]}`,
            border: `2px solid ${PRIMARY_COLOR.P2}`,
        };
        const scheduleEventStyleMap = {
            [SCHEDULE_EVENT_TYPE.DEFAULT]: scheduleEventDefaultStyle,
            [SCHEDULE_EVENT_TYPE.DUPLICATED]: duplicatedScheduleEventStyle,
            [SCHEDULE_EVENT_TYPE.HIGHLIGHT]: highLightScheduleEventStyle,
            [SCHEDULE_EVENT_TYPE.TEMPORARY]: temporaryScheduleEventStyle,
        };
        const createScheduleEvents = () => {
            if (weeklyScheduler.value && !isEmptyArray(props.scheduleEventList)) {
                const scheduleEventList = props.scheduleEventList.map((scheduleEvent) => ({
                    ...scheduleEvent,
                    isReadonly: true,
                    calendarId: CALENDAR_ID,
                    customStyle: scheduleEventStyleMap[scheduleEvent.eventType ?? SCHEDULE_EVENT_TYPE.DEFAULT],
                }));
                weeklyScheduler.value.createEvents(scheduleEventList);
            }
        };
        onMounted(() => {
            createScheduler();
            createScheduleEvents();
        });
        watch(() => props.scheduleEventList, () => {
            weeklyScheduler.value?.clear();
            createScheduleEvents();
        });
        return {
            weeklySchedulerContainer,
        };
    },
});
