import { useModal } from 'vue-final-modal';
import { BasicModal } from '@hems/component';
import { BASIC_MODAL } from '@hems/util/src/constant';
export const useAlertModal = (message, callback) => {
    const alertModal = useModal({
        component: BasicModal,
        attrs: {
            type: BASIC_MODAL.ALERT,
            content: message,
            onClose: callback,
        },
    });
    return alertModal;
};
