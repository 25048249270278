import { computed, defineComponent, nextTick, reactive, watch, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useTimestamp } from '@vueuse/core';
import _ from 'lodash';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { Accordion, LabelInput, TruncateInput, BasicInput, ToggleInput, GoogleMapAutoComplete, GoogleMapWrapper, ImageUploader, useMessageBox, SettingTable, SettingTableRow, Tooltip, withLoading, Selector, RadioGroup, ErrorMessageLabel, LoadingPopup, SignPadViewer, } from '@hems/component';
import { CommonService, DeviceServiceInstaller } from '@hems/service';
import { Helper } from '@hems/util';
import { useSiteDeviceGenType } from '@hems/util/src/composable';
import { INSTALLATION_POPUP_NAME } from '@hems/util/src/constant';
import { AxiosErrorException } from '@hems/util/src/exception/exception';
import { alarmCodeMap } from './alarmConfig';
import ReplaceDevicePopup from './ReplaceDevicePopup.vue';
import ReplaceNMIDevicePopup from './ReplaceNMIDevicePopup.vue';
export default defineComponent({
    name: 'InstallationContainer',
    components: {
        Accordion,
        LabelInput,
        TruncateInput,
        BasicInput,
        ToggleInput,
        Form,
        GoogleMapWrapper,
        GoogleMapAutoComplete,
        ImageUploader,
        SignPadViewer,
        ReplaceDevicePopup,
        ReplaceNMIDevicePopup,
        SettingTable,
        SettingTableRow,
        Tooltip,
        Selector,
        RadioGroup,
        ErrorMessageLabel,
        LoadingPopup,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        deviceId: {
            type: String,
            required: true,
        },
        productModelNm: {
            type: String,
            required: true,
        },
        siteId: {
            type: Number,
            required: true,
        },
        langCd: {
            type: String,
            required: true,
        },
        roleNm: {
            type: String,
            required: true,
        },
        apiKey: {
            type: String,
            required: true,
        },
        isConnection: {
            type: Boolean,
            required: true,
        },
        nmiInfo: {
            type: Object,
            required: true,
        },
        genType: {
            type: String,
            required: true,
        },
        isFcas: {
            type: Boolean,
            required: true,
        },
        sapnInfo: {
            type: Object,
            default: () => ({ isSAPN: false, ems_ver: '' }),
        },
    },
    emits: [
        'goToList',
        'save',
        'afterReplaceDevice',
        'afterRemoveDevice',
        'afterDeleteDevice',
        'afterRollbackDevice',
        'afterModifyNMIDevice',
    ],
    // eslint-disable-next-line complexity
    async setup(props, { emit }) {
        let copyData;
        let copyRetailer = null;
        let copyBlobImg = null;
        const deviceService = new DeviceServiceInstaller(window.axiosInstance.axios);
        const commonService = new CommonService(window.axiosInstance.axios);
        const messageBox = useMessageBox();
        const { t } = useI18n();
        const isEditable = ref(false);
        const { isGEN3 } = useSiteDeviceGenType();
        const activateMessage = ref(false);
        const state = reactive({
            language: computed(() => props.langCd),
            data: props.data ? { ...props.data } : null,
            activateStatus: '',
            deleteStatus: '',
            googleMap: null,
            isGoogleMapLoaded: false,
            blobImg: null,
            nmiDeviceId: props.nmiInfo.nmiDeviceId ?? '',
            rollbackDeviceId: '',
            retailerCd: props.nmiInfo.retailerCd ?? null,
            notiAlarms: alarmCodeMap.map((item) => ({
                enabled: (Helper.isNull(props.data?.enabled_noti_alarms) ? '' : props.data?.enabled_noti_alarms)?.includes(item.code)
                    ? 1
                    : 0,
                alarmCd: item.code,
                show: !!item.genType.includes(String(props.genType)),
            })),
            popup: {
                replaceDevice: { on: false },
                replaceNMIDevice: { on: false },
                sapnLoading: {
                    on: false,
                    defaultMessage: 'SAPN registration is in progress. Please wait.',
                    displayingMessage: 'SAPN registration is in progress. Please wait.',
                    inProgress: true,
                    isSuccess: false,
                },
            },
            sapnValidationInfo: {
                isValidEmsVersion: props.genType
                    ? Helper.checkValidEmsVersion(props.genType, props.sapnInfo.ems_ver, 23) ?? false
                    : false,
                errorMessage: getSapnErrorMessage(),
            },
            lfdi: props.data?.lfdi,
            validClass: {
                retailerCd: 'ok',
            },
        });
        const isSapnDataAvailable = props.sapnInfo.isSAPN && state.data?.is_sapn === 'Y';
        const isSapnGenType = props.genType && isGEN3;
        const isSapnActive = isSapnDataAvailable && isSapnGenType;
        const isReplaceNMIDeviceActive = computed(() => state.popup.replaceNMIDevice.on && state.nmiDeviceId);
        const openPopup = (popupName) => {
            if (popupName === INSTALLATION_POPUP_NAME.REPLACE_DEVICE ||
                popupName === INSTALLATION_POPUP_NAME.REPLACE_NMI_DEVICE) {
                state.popup[popupName] = { on: true };
            }
            else if (popupName === INSTALLATION_POPUP_NAME.SAPN_LOADING) {
                state.popup[popupName] = { ...state.popup[popupName], on: true };
            }
        };
        const closePopup = (popupName) => {
            if (popupName === INSTALLATION_POPUP_NAME.REPLACE_DEVICE ||
                popupName === INSTALLATION_POPUP_NAME.REPLACE_NMI_DEVICE) {
                state.popup[popupName] = { on: false };
            }
            else if (popupName === INSTALLATION_POPUP_NAME.SAPN_LOADING) {
                state.popup[popupName] = { ...state.popup[popupName], on: false };
            }
        };
        const onClickReplacement = async () => {
            const confirmReplacement = await messageBox
                .confirm([`${t('message.inform_qcell_manager')}`, `${t('message.limit_service')}`])
                .open();
            if (confirmReplacement) {
                openPopup(INSTALLATION_POPUP_NAME.REPLACE_DEVICE);
            }
        };
        const onClickEmail = async () => {
            const confirmSendEmail = await messageBox
                .confirm(t('message.resend_email', { email: state.data?.homeowner_email }))
                .open();
            if (confirmSendEmail) {
                const emailData = {
                    device_id: props.deviceId,
                    pin_code: String(state.data?.instl_pin_code) ?? '',
                    email: state.data?.homeowner_email ?? '',
                };
                try {
                    await commonService.sendOwnerEmail(props.siteId, emailData);
                }
                catch (e) {
                    await messageBox.alert(t('message.email_delivery_failed')).open();
                    console.error(e);
                }
            }
        };
        const countryInfo = computed(() => {
            const countryCode = state.data?.cntry_cd;
            const countryName = state.data?.cntry_nm;
            if (Helper.isNull(countryCode)) {
                return '';
            }
            if (Helper.isNull(countryName)) {
                return countryCode;
            }
            if (countryCode.includes('code')) {
                return `${countryCode} (${t(countryName)})`;
            }
            return `${countryCode} (${countryName})`;
        });
        const isNMI = computed(() => props.nmiInfo.isNMI ?? false);
        const isFCAS = computed(() => (props.isFcas && ['admin', 'dev'].includes(props.roleNm)) ?? false);
        watch(() => props.data, () => {
            if (props.data) {
                state.data = { ...props.data };
            }
        });
        watch(() => state.googleMap, (status) => {
            if (status !== null)
                state.isGoogleMapLoaded = true;
        });
        watch(() => state.retailerCd, async () => {
            if (!Helper.isNull(state.retailerCd) && Helper.isNull(state.nmiDeviceId)) {
                const check = await messageBox.alert(t('message.cannot_set_retailer')).open();
                if (!check) {
                    state.retailerCd = null;
                }
            }
        });
        onMounted(() => {
            if (localStorage.getItem('activateStatus')) {
                const BeforeTimeStamp = Number(localStorage.getItem('activateStatus'));
                const AfterTimeStamp = useTimestamp({ offset: 0 }).value;
                if (AfterTimeStamp - BeforeTimeStamp >= 60000) {
                    localStorage.removeItem('activateStatus');
                    activateMessage.value = false;
                }
                else {
                    state.activateStatus = '0';
                    activateMessage.value = true;
                    setTimeout(() => {
                        location.reload();
                    }, BeforeTimeStamp + 60000 - AfterTimeStamp);
                }
            }
        });
        const { RETAILER_NAME_CD } = await commonService.getCodesByGroupCode([{ grpCd: 'RETAILER_NAME_CD' }]);
        const retailerNameCdList = Helper.codeNamesToSelectorOptions(RETAILER_NAME_CD, t, {
            text: t('common.none'),
            value: null,
        }).map((item) => (item.value === '1' ? Object.assign(item, { disabled: true }) : item));
        const yesOrNoSelectorOptions = Helper.addSelectorOptionAtFirst([
            {
                text: t('common.yes'),
                value: 'Y',
            },
            {
                text: t('common.no'),
                value: 'N',
            },
        ], { text: t('common.select'), value: null });
        let mapLoadedCount = 0;
        const maxCount = 42; // 0.7초당 1번 - 최대 30초
        const timer = (millisec) => new Promise((res) => setTimeout(res, millisec));
        async function loaded() {
            while (!state.isGoogleMapLoaded) {
                await timer(700);
                if (++mapLoadedCount >= maxCount) {
                    break;
                }
            }
        }
        withLoading(async () => await loaded())();
        // load image
        deviceService.getImageBySiteId(props.siteId).then((blobImg) => {
            if (blobImg.size === 0) {
                state.blobImg = null;
                return;
            }
            state.blobImg = blobImg;
            if (state.data?.file) {
                state.data.file = new File([blobImg], 'file.png', { type: 'image/png' });
            }
        });
        function getParam() {
            // 위치정보, 이미지 정보 저장...
            let param = {};
            const { instl_addr, latitude, longitude, cntry_cd, cntry_nm, instl_comn_nm, instl_mpn_no, instl_email, instl_pin_code, timezone_id, file, homeowner_nm, homeowner_pn, homeowner_email, instl_state, instl_postal_code, off_grid, is_test, force_instl_addr, memo, } = state.data || {};
            param = {
                instl_addr,
                latitude,
                longitude,
                cntry_cd,
                cntry_nm,
                instl_comn_nm,
                instl_mpn_no,
                instl_email,
                instl_pin_code,
                timezone_id,
                file,
                homeowner_nm,
                homeowner_pn,
                homeowner_email,
                instl_state,
                instl_postal_code,
                off_grid,
                is_test,
                force_instl_addr,
                memo,
            };
            param.enabled_noti_alarms = getEnabledNotiAlarms();
            if (props.sapnInfo.isSAPN && state.data?.is_sapn === 'Y') {
                param.dynamic_export = state.data.dynamic_export;
                param.dynamic_export_owner = state.data.dynamic_export_owner;
            }
            if (isNMI.value && !Helper.isNull(state.nmiDeviceId)) {
                return { ...param, nmi_device_id: state.nmiDeviceId, retailer_cd: state.retailerCd };
            }
            return param;
        }
        function getEnabledNotiAlarms() {
            const enabledAlarms = state.notiAlarms.filter((item) => item.show && item.enabled).map((item) => item.alarmCd);
            return enabledAlarms.join(',');
        }
        function onSave() {
            emit('save', getParam(), (isOk) => {
                if (isOk)
                    isEditable.value = false;
            });
        }
        function onEdit() {
            if (state.data) {
                copyData = _.cloneDeep(state.data);
            }
            copyRetailer = state.retailerCd;
            copyBlobImg = state.blobImg;
            isEditable.value = true;
        }
        function onCancel(handleReset) {
            state.data = copyData;
            state.retailerCd = copyRetailer;
            isEditable.value = false;
            state.blobImg = null;
            nextTick(() => {
                state.blobImg = copyBlobImg;
            });
            if (handleReset)
                handleReset();
        }
        function goToList() {
            emit('goToList');
        }
        async function replaceDevice(newDeviceInfo) {
            const params = { ...newDeviceInfo, deviceId: props.deviceId };
            try {
                await deviceService.replaceDevice(props.siteId, params);
                emit('afterReplaceDevice', props.siteId, newDeviceInfo.newDeviceId);
            }
            catch (e) {
                console.error(e);
                await messageBox.alert(t('message.error_replace_device')).open();
            }
            finally {
                closePopup('replaceDevice');
            }
        }
        async function registerNMI(newNMIDeviceId) {
            try {
                await deviceService.registerNMI(props.siteId, { nmiDeviceId: newNMIDeviceId, deviceId: props.deviceId });
                state.nmiDeviceId = newNMIDeviceId;
                emit('afterModifyNMIDevice', { newNMIDeviceId, siteId: props.siteId, deviceId: props.deviceId });
            }
            catch (e) {
                console.error(e);
                if (e instanceof AxiosErrorException) {
                    e.afterError({
                        500: async () => {
                            await messageBox.alert(t('message.error_nmi_reg')).open();
                        },
                    });
                }
            }
            finally {
                closePopup(INSTALLATION_POPUP_NAME.REPLACE_NMI_DEVICE);
            }
        }
        async function replaceNMI(newNMIDeviceId) {
            if (!state.nmiDeviceId)
                return;
            try {
                await deviceService.replaceNMI(props.siteId, {
                    nmiDeviceId: state.nmiDeviceId,
                    newNmiDeviceId: newNMIDeviceId,
                    deviceId: props.deviceId,
                });
                state.nmiDeviceId = newNMIDeviceId;
                emit('afterModifyNMIDevice', { newNMIDeviceId, siteId: props.siteId, deviceId: props.deviceId });
            }
            catch (e) {
                console.error(e);
                if (e instanceof AxiosErrorException) {
                    e.afterError({
                        500: async () => {
                            await messageBox.alert(t('message.error_nmi_replace')).open();
                        },
                    });
                }
            }
            finally {
                closePopup(INSTALLATION_POPUP_NAME.REPLACE_NMI_DEVICE);
            }
        }
        async function setNMI(newNMIDeviceId) {
            if (!isNMI.value)
                return;
            if (state.nmiDeviceId) {
                await replaceNMI(newNMIDeviceId);
            }
            else {
                await registerNMI(newNMIDeviceId);
            }
        }
        async function removeNMI() {
            if (!state.nmiDeviceId || !props.siteId)
                return;
            if (!(await messageBox.confirm([t('message.ask_delete')]).open()))
                return;
            try {
                await deviceService.removeNMI(props.siteId, state.nmiDeviceId, props.deviceId);
                state.nmiDeviceId = '';
                emit('afterModifyNMIDevice', { siteId: props.siteId, deviceId: props.deviceId });
            }
            catch (e) {
                console.error(e);
                await messageBox.alert(t('message.unknown_error')).open();
            }
        }
        async function deleteDevice() {
            if (!(await messageBox.confirm([`${t('message.inform_deletion')}`, `${t('message.inform_proceed')}`]).open()))
                return;
            try {
                await deviceService.deleteDevice(props.deviceId);
                emit('afterDeleteDevice');
            }
            catch (e) {
                console.error(e);
                messageBox.alert(t('message.error_delete_device')).open();
            }
        }
        async function rollbackDevice() {
            if (!(await messageBox.confirm([`${t('message.inform_rollback')}`, `${t('message.inform_proceed')}`]).open()))
                return;
            try {
                await deviceService.rollbackDevice(props.deviceId).then((data) => {
                    state.rollbackDeviceId = data.after_device_id;
                });
                if (!(await messageBox.alert([t('message.complete_rollback_device')]).open()))
                    emit('afterRollbackDevice', props.siteId, state.rollbackDeviceId);
            }
            catch (e) {
                console.error(e);
                messageBox.alert(t('message.error_rollback_device')).open();
            }
        }
        async function removeDevice() {
            if (!(await messageBox.confirm([`${t('message.inform_qcell_manager')}`, `${t('message.limit_service')}`]).open()))
                return;
            try {
                const params = { deviceId: props.deviceId };
                await deviceService.removeDevice(props.siteId, params);
                emit('afterRemoveDevice');
            }
            catch (e) {
                console.error(e);
                messageBox.alert(t('message.error_remove_device')).open();
            }
        }
        const prevLocation = {
            value: null,
            status: false,
        };
        const currentLocation = {
            value: { lat: props.data?.latitude ?? 0, lon: props.data?.longitude ?? 0 },
            status: true,
        };
        const schema = yup.object().shape({
            cntry_cd: yup.string().required(),
            instl_addr: yup
                .string()
                .required()
                .isValidLatLon(prevLocation, { latKey: 'latitude', lonKey: 'longitude' }, currentLocation),
            latitude: numberValiate(),
            longitude: numberValiate(),
            timezone_id: yup.string().nullable().required(),
            instl_pin_code: yup
                .string()
                .matches(/^(?:\d{4}|\d{6})$/, { message: { key: 'message.invalid_format' } })
                .required(),
            owner_email: yup.string().email().nullable().required(),
            owner_name: yup.string().trim().min(1).nullable().required(),
            owner_contact: yup
                .string()
                .test('mpn_no', { key: 'message.invalid' }, function (mpn_no) {
                const { createError } = this;
                if (Helper.isNull(mpn_no)) {
                    return createError({
                        message: { key: 'message.field_required' },
                    });
                }
                const regexp = /^[0-9+\-()]+$/g;
                if (!regexp.test(mpn_no)) {
                    return false;
                }
                return true;
            })
                .nullable()
                .required(),
            instl_state: yup
                .string()
                .nullable()
                .when({
                is: (v) => !Helper.isNull(v),
                then: yup.string().nullable().required(),
            }),
            instl_postal_code: yup
                .string()
                .nullable()
                .when({
                is: (v) => !Helper.isNull(v),
                then: yup.string().nullable().required(),
            }),
            retailerCd: yup.string().nullable().notRequired(),
        });
        function numberValiate() {
            return yup
                .number()
                .transform((v, o) => (o === '' ? null : v))
                .nullable()
                .required();
        }
        function onChangeMapPosition(position, timezoneInfo, statePostalInfo) {
            const { lat, lng, address, cntryCd, cntryNm } = position;
            if (!state.data)
                return;
            state.data.latitude = lat;
            state.data.longitude = lng;
            state.data.cntry_cd = cntryCd;
            state.data.cntry_nm = cntryNm;
            if (isEditable.value)
                state.data.instl_addr = address;
            state.data.timezone_id = timezoneInfo.timeZoneId;
            state.data.instl_state = statePostalInfo.state;
            state.data.instl_postal_code = statePostalInfo.postalCode;
        }
        function onChangeAddress(position, timezoneInfo, statePostalInfo, address) {
            const { lat, lng, cntryCd, cntryNm } = position;
            if (!state.data)
                return;
            state.data.latitude = lat;
            state.data.longitude = lng;
            state.data.cntry_cd = cntryCd;
            state.data.cntry_nm = cntryNm;
            state.data.instl_addr = address;
            state.data.timezone_id = timezoneInfo.timeZoneId;
            state.data.instl_state = statePostalInfo.state;
            state.data.instl_postal_code = statePostalInfo.postalCode;
        }
        function onChangeCustomAddress(address) {
            if (!state.data)
                return;
            state.data.instl_addr = address;
        }
        function setSapnDisplayingMessage(result, lfdi) {
            if (result === 0) {
                state.popup.sapnLoading.displayingMessage = [
                    'SAPN server registration is complete. Please check LFDI.',
                    `LFDI: ${lfdi}`,
                ];
                state.popup.sapnLoading.isSuccess = true;
            }
            else if (result === 1) {
                state.popup.sapnLoading.displayingMessage = 'Error occurs due to timeout.';
            }
            else {
                state.popup.sapnLoading.displayingMessage = 'SAPN registration has failed.';
            }
        }
        async function retrySAPNRegistration() {
            try {
                // lfdi 발급 재시도
                state.popup.sapnLoading.on = true;
                state.popup.sapnLoading.inProgress = true;
                state.popup.sapnLoading.displayingMessage = state.popup.sapnLoading.defaultMessage;
                const { result } = await deviceService.registerSapn({ siteId: props.siteId, lfdiFlag: 1 });
                if (result.responseCd === '0') {
                    setSapnDisplayingMessage(0, result.lfdi);
                    state.lfdi = result.lfdi;
                    state.sapnValidationInfo.errorMessage = '';
                }
                else if (result.responseCd === '1') {
                    setSapnDisplayingMessage(1, result.lfdi);
                    state.sapnValidationInfo.errorMessage = 'Error occurs due to timeout.';
                }
                else {
                    setSapnDisplayingMessage(-1, result.lfdi);
                    state.sapnValidationInfo.errorMessage = 'SAPN registration has failed.';
                }
                state.popup.sapnLoading.inProgress = false;
            }
            catch (e) {
                console.error(e);
                state.popup.sapnLoading.inProgress = false;
                setSapnDisplayingMessage(-1, '');
                state.sapnValidationInfo.errorMessage = 'SAPN registration has failed.';
            }
        }
        function getSapnErrorMessage() {
            const isValidEmsVersion = props.genType
                ? Helper.checkValidEmsVersion(props.genType, props.sapnInfo.ems_ver, 23) ?? false
                : false;
            if (!isValidEmsVersion) {
                return 'Older version of FW does not support this feature.';
            }
            if (Helper.isNull(props.data?.lfdi) && !Helper.isNull(props.data?.lfdi_result_cd)) {
                if (props.data?.lfdi_result_cd === '1') {
                    return 'Error occurs due to timeout.';
                }
                return 'SAPN registration has failed.';
            }
            return '';
        }
        async function getDeleteStatus() {
            const params = { deviceId: props.deviceId };
            await deviceService.checkDeleteStatus(props.siteId, params).then((data) => {
                state.activateStatus = data.activate;
                state.deleteStatus = data.type;
            });
        }
        if (props.roleNm === 'admin' ||
            props.roleNm === 'dev' ||
            props.roleNm === 'installer' ||
            props.roleNm === 'service') {
            await Promise.all([getDeleteStatus()]);
        }
        return {
            state,
            schema,
            onChangeMapPosition,
            onChangeAddress,
            onChangeCustomAddress,
            goToList,
            onEdit,
            onCancel,
            onSave,
            openPopup,
            closePopup,
            deleteDevice,
            rollbackDevice,
            removeDevice,
            replaceDevice,
            setNMI,
            removeNMI,
            instance: (instance) => {
                state.googleMap = instance?.map ?? null;
            },
            retailerNameCdList,
            onClickEmail,
            onClickReplacement,
            yesOrNoSelectorOptions,
            retrySAPNRegistration,
            isNull: Helper.isNull,
            isEditable,
            isSapnActive,
            INSTALLATION_POPUP_NAME,
            isReplaceNMIDeviceActive,
            countryInfo,
            isNMI,
            isFCAS,
            activateMessage,
        };
    },
});
