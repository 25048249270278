export const BASIC_MODAL = {
    ALERT: 'alert',
    CONFIRM: 'confirm',
    REMOVE: 'remove',
};
export const MESSAGE_MODAL_TYPE = {
    /** 경고 메시지 */
    WARNING: 'WARNING',
    /** 위험한 동작일 때 확인 모달 (ex 삭제) */
    DANGER_CONFIRMATION: 'DANGER_CONFIRMATION',
    /** 일반 확인 모달 */
    CONFIRMATION: 'CONFIRMATION',
};
