import { defineComponent, reactive, computed, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { LabelInput, Searchbox } from '@hems/component';
import { CommonService } from '@hems/service';
import { SUPPORT_GUIDE_FILTER_TYPE } from '@hems/util/src/constant';
import { codeNamesToSelectorOptions } from '@hems/util/src/helper/helper';
export default defineComponent({
    name: 'SupportSearchBox',
    components: {
        Searchbox,
        LabelInput,
        // TwoDepthSelector,
    },
    props: {
        condition: {
            type: Object,
            default: () => ({
                category: null,
                searchFor: null,
            }),
        },
    },
    emits: ['search'],
    async setup(props, { emit }) {
        const commonService = new CommonService(window.axiosInstance.axios);
        const { t } = useI18n();
        const state = reactive({
            searchCondition: props.condition,
            categoryFilterOptions: [],
        });
        const countryCode = ref([]);
        const searchInputPlaceholder = computed(() => `${t('common.search')} ${t('common.guide', { defaultValue: 'Guide' })}, ${t('device.err_code')}`);
        const getCodeGroups = async () => {
            const { CNTRY_CD } = await commonService.getCodesByGroupCode([{ grpCd: 'CNTRY_CD' }]);
            countryCode.value = CNTRY_CD;
        };
        const onSearch = () => {
            if (state.searchCondition) {
                state.searchCondition.searchFor = state.searchCondition.searchFor?.trim();
            }
            emit('search', { ...state.searchCondition });
        };
        // 이중 필터링 목록으로 수정 필요
        const guideFilteringList = computed(() => [
            { text: t('common.all'), value: '' },
            ...codeNamesToSelectorOptions(countryCode.value, t),
        ]);
        // TODO: 카테고리 정의된 후 옵션 재수정 예정
        const guideSubCategoryFilterOptions = computed(() => [
            { text: t('device.battery'), value: SUPPORT_GUIDE_FILTER_TYPE.BATTERY },
            { text: t('common.box', { defaultValue: 'Box' }), value: SUPPORT_GUIDE_FILTER_TYPE.BOX },
            { text: t('device.micro_inverter'), value: SUPPORT_GUIDE_FILTER_TYPE.MICRO_INVERTER },
            { text: t('common.solar'), value: SUPPORT_GUIDE_FILTER_TYPE.SOLAR },
        ]);
        state.categoryFilterOptions = [
            {
                label: '',
                open: true,
                options: [
                    {
                        text: t('common.all'),
                        value: null,
                    },
                ],
            },
            {
                label: t('common.quick_guide'),
                open: false,
                options: guideSubCategoryFilterOptions.value,
            },
            { label: t('common.install_manual'), open: false, options: guideSubCategoryFilterOptions.value },
            {
                label: t('common.spec_sheet', { defaultValue: 'Spec Sheet' }),
                open: false,
                options: guideSubCategoryFilterOptions.value,
            },
            { label: t('common.user_maual'), open: false, options: guideSubCategoryFilterOptions.value },
            {
                label: t('common.installer_app_guide', { defaultValue: 'Installer App Guide' }),
                open: false,
                options: guideSubCategoryFilterOptions.value,
            },
            {
                label: t('common.installer_web_guide', { defaultValue: 'Installer Web Guide' }),
                open: false,
                options: guideSubCategoryFilterOptions.value,
            },
        ];
        onMounted(() => {
            getCodeGroups();
        });
        return {
            state,
            onSearch,
            guideFilteringList,
            searchInputPlaceholder,
        };
    },
});
