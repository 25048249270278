import { defineComponent } from 'vue';
import ToggleButton from '@hems/component/src/buttons/ToggleButton.vue';
export default defineComponent({
    name: 'ToggleButtonGroup',
    components: { ToggleButton },
    props: {
        selectedItemValues: {
            type: Array,
            required: true,
        },
        toggleButtonItems: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['change'],
    setup(props, { emit }) {
        const isSelectedItem = (value) => props.selectedItemValues.includes(value);
        const toggleItem = (selectedValue) => {
            const isSelected = isSelectedItem(selectedValue);
            emit('change', selectedValue, !isSelected);
        };
        return { toggleItem, isSelectedItem };
    },
});
