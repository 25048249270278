export const GEN_TYPE = {
    GEN2: 'GEN2',
    GEN3: 'GEN3',
    FOX_ESS: 'FOX_ESS',
    FOX_ESS_H3: 'FOX_ESS_H3',
    AC_SYS: 'AC_SYS',
    // @TODO: 하기 항목 추후 삭제
    MLPE_GEN1: 'MLPE_GEN1',
    DC_OPTIMIZER_GEN2: 'DC_OPTIMIZER_GEN2',
};
export const AC_DEVICE_TYPE = {
    /** Stand Alone AC Combiner  (case 1) */
    AC_COMBINER: 'AC_COMB',
    /** AC Combiner + Multi ESS + Hub (case 3) */
    AC_COMBINER_MULTI_ESS_HUB: 'AC_COMB_MULTI_ESS_HUB',
    /** Third Party + Multi ESS + Hub (case 4-1) */
    AC_THIRD_PARTY_MULTI_ESS_HUB: 'AC_THIRD_PARTY_MULTI_ESS_HUB',
    /** Third Party + Stand Alone ESS + Hub (case 4-2) */
    AC_THIRD_PARTY_ESS_HUB: 'AC_THIRD_PARTY_ESS_HUB',
    /** Third Party + Stand Alone ESS (case 5) */
    AC_THIRD_PARTY_ESS: 'AC_THIRD_PARTY_ESS',
    /** AC Combiner + Multi ESS (case 6) */
    AC_COMBINER_MULTI_ESS: 'AC_COMB_MULTI_ESS',
    /** Stand Alone AC ESS (case 8-1) */
    AC_ESS: 'AC_ESS',
    /** AC Multi ESS (case 8-2) */
    AC_MULTI_ESS: 'AC_MULTI_ESS',
};
export const AC_MODEL_TYPE = {
    AC_COMBINER: 'ACCB',
    AC_ESS: 'ACES',
};
export const EMS_TYPE = {
    STAND_ALONE: '0',
    PRIMARY: '1',
    SECONDARY: '2',
};
export const EMS_PRIORITY_ORDER_MAP = {
    [EMS_TYPE.STAND_ALONE]: 1,
    [EMS_TYPE.PRIMARY]: 2,
    [EMS_TYPE.SECONDARY]: 3,
};
export const ACSystemModelNamePrefixes = ['AACCB', 'AACES'];
export const CONSUMPTION_CT_TYPE = {
    GRID_SIDE: 0,
    LOAD_SIDE: 1,
    NOT_INSTALLED: 2,
};
export const HUB_GENERATOR_FLAG = {
    NOT_USED: 0,
    USED: 1,
};
export const THIRD_PARTY_CONTROL_TYPE = {
    ENABLE: '1',
    DISABLE: '0',
};
const ThirdPartyControlTextMap = {
    [THIRD_PARTY_CONTROL_TYPE.ENABLE]: 'enable',
    [THIRD_PARTY_CONTROL_TYPE.DISABLE]: 'disable',
};
export const getThirdPartyControlText = (controlType) => {
    return ThirdPartyControlTextMap[controlType];
};
export const HISTORY_INFO_CHANGE_TYPE_CODE = {
    INSERT: 'INSERT',
    REPLACE: 'REPLACE',
    REPLACE_MI: 'REPLACE_MI',
    DELETE: 'DELETE',
};
export const HISTORY_DEVICE_TYPE_CD = {
    AC_COMB: 'AC_COMB',
    AC_ESS: 'AC_ESS',
    FOX_GW_EMS: 'FOX_GW_EMS',
    FOX_INV: 'FOX_INV',
    GW_INV: 'GW_INV',
};
export const OPERATION_MODE = {
    /** No Operation */
    NOP: 0,
    /** Auto */
    AUTO: 1,
    /** Manual */
    MANUAL: 2,
};
export const OPERATION_MODE_I18N_KEY_MAP = {
    [OPERATION_MODE.NOP]: 'control.nop',
    [OPERATION_MODE.AUTO]: 'device.auto',
    [OPERATION_MODE.MANUAL]: 'common.manual',
};
