import { defineComponent, onMounted, reactive, ref, watch, computed } from 'vue';
import { gsap } from 'gsap';
import { useCheckMobile } from '@hems/util';
import { ERROR_STATUS_TYPE } from '@hems/util/src/constant/eventHistory';
export default defineComponent({
    name: 'Accordion',
    props: {
        title: {
            type: String,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
        showStatus: {
            type: Boolean,
            default: false,
        },
        statusType: {
            type: String,
            default: null,
        },
        subTitle: {
            type: String,
            default: '',
        },
        isFixedOpen: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { isMobile } = useCheckMobile();
        const content = ref();
        const state = reactive({
            active: props.isFixedOpen || props.active,
            // TODO: 일단 Warning은 정상 표기되도록함
            statusType: props.statusType === ERROR_STATUS_TYPE.ERROR
                ? 'error'
                : props.statusType === ERROR_STATUS_TYPE.WARNING
                    ? undefined
                    : undefined,
            height: props.isFixedOpen || props.active ? 'auto' : 0,
        });
        const stateClassName = computed(() => {
            return `${state.active ? 'active' : ''} ${state.statusType ? `${state.statusType}` : ''}`;
        });
        const accordionClassName = isMobile ? 'accordion_mobile' : 'accordion';
        const onClick = () => {
            if (!props.isFixedOpen) {
                state.active = !state.active;
            }
        };
        const slideUp = () => {
            gsap.to([content.value], {
                height: 0,
                duration: 0.4,
            });
        };
        const slideDown = () => {
            gsap.to([content.value], {
                height: 'auto',
                duration: 0.4,
            });
        };
        const setMobileAccordionBorderStyle = () => {
            const element = content.value;
            if (element && element instanceof HTMLElement) {
                const slotElements = Array.from(element.children);
                slotElements.forEach((child, index) => {
                    if (index < slotElements.length - 1) {
                        if (child instanceof HTMLElement) {
                            child.style.borderBottom = '1px solid #f0f0f0';
                        }
                    }
                    else {
                        if (child instanceof HTMLElement) {
                            child.style.borderBottom = '1px solid transparent';
                        }
                    }
                });
            }
        };
        onMounted(() => {
            gsap.set([content.value], {
                height: state.active ? 'auto' : 0,
            });
            if (isMobile) {
                setMobileAccordionBorderStyle();
            }
        });
        watch(() => state.active, (active) => {
            if (!props.isFixedOpen) {
                active ? slideDown() : slideUp();
            }
        });
        return {
            state,
            content,
            stateClassName,
            accordionClassName,
            onClick,
            isMobile,
        };
    },
});
