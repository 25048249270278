import { Exceptions } from '@hems/util';
const { AxiosErrorException } = Exceptions;
export class Service {
    axios;
    constructor(axios) {
        this.axios = axios;
    }
    async download(url, params, defaultFileName) {
        try {
            const { data, headers } = await this.axios.get(url, {
                params,
                headers: {
                    Accept: '*/*',
                },
                responseType: 'blob',
            });
            const items = headers['content-disposition']?.split(';');
            const filename = items
                .map((item) => {
                const temp = item.split('=');
                return { key: temp?.[0]?.trim(), value: temp?.[1]?.replace(/[" ]/gi, '') };
            })
                .find((item) => item.key === 'filename');
            return { data, filename: filename?.value || defaultFileName || 'unknown' };
        }
        catch (e) {
            throw new AxiosErrorException(url, params, e);
        }
    }
    async get(url, params, config) {
        try {
            const { data } = await this.axios.get(url, { params, ...config });
            return data;
        }
        catch (e) {
            throw new AxiosErrorException(url, params, e);
        }
    }
    async put(url, params, config) {
        try {
            const { data } = await this.axios.put(url, params, config);
            return data;
        }
        catch (e) {
            throw new AxiosErrorException(url, params, e);
        }
    }
    async post(url, params, config) {
        try {
            const { data } = await this.axios.post(url, params, config);
            return data;
        }
        catch (e) {
            throw new AxiosErrorException(url, params, e);
        }
    }
    async delete(url, params, config) {
        try {
            const { data } = await this.axios.delete(url, { params, ...config });
            return data;
        }
        catch (e) {
            throw new AxiosErrorException(url, params, e);
        }
    }
}
