import { computed, defineComponent } from 'vue';
import { useRoute, RouterLink } from 'vue-router';
import { useStore } from 'vuex';
import { useACDeviceType } from '@hems/util';
export default defineComponent({
    name: 'MajorTab',
    components: {
        RouterLink,
    },
    setup() {
        const route = useRoute();
        const siteId = computed(() => route.query.siteId);
        const deviceId = computed(() => route.query.deviceId);
        const statusId = computed(() => route.query.statusId);
        const tabname = computed(() => route.path.replace('/remote/monitoring/details/', '').split('/')[0]);
        const store = useStore();
        const roleNm = store.state.user.roleNm;
        const { hasACCombiner } = useACDeviceType();
        return {
            siteId,
            deviceId,
            statusId,
            tabname,
            roleNm,
            hasACCombiner,
        };
    },
});
