import { defineComponent } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import { useI18n } from 'vue-i18n';
import { Image2 } from '@hems/component';
import { BASIC_MODAL } from '@hems/util/src/constant';
export default defineComponent({
    name: 'BasicModal',
    components: { VueFinalModal, Image2 },
    props: {
        type: {
            type: String,
            default: BASIC_MODAL.ALERT,
        },
        title: {
            type: String,
            default: '',
        },
        content: {
            type: String,
            default: '',
        },
    },
    emits: ['confirm', 'close'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const closeButtonTextCode = props.type === BASIC_MODAL.ALERT ? t('common.close') : t('common.cancel');
        const closeButtonClass = props.type === BASIC_MODAL.CONFIRM ? 'button-cancel' : 'button-confirm';
        const onClose = (close) => {
            emit('close');
            close();
        };
        return {
            closeButtonTextCode,
            closeButtonClass,
            onClose,
            BASIC_MODAL,
        };
    },
});
