import { Helper } from '@hems/util';
import { getEnergyControlValueForAC } from '@hems/util/src/constant/dashboard';
import { formatToThreeDecimalVersion, formatToTwoDecimalVersion } from '@hems/util/src/helper/dashboardHelper';
export const getInverterProfileFOX = (data, inverterData, BMSData, t) => {
    return {
        title: 'Standalone Inverter',
        content: [
            {
                key: 'device_id',
                title: t('device.serial_no'),
                value: inverterData?.device_id,
            },
            {
                key: 'pcs_ver1',
                title: `${t('device.pcs_ver')} 1`,
                value: data.pcs_ver ? formatToTwoDecimalVersion(data.pcs_ver) : null,
            },
            {
                key: 'pcs_ver2',
                title: `${t('device.pcs_ver')} 2`,
                value: data.pcs_ver2 ? formatToTwoDecimalVersion(data.pcs_ver2) : null,
            },
            {
                key: 'bms_ver3',
                title: `${t('device.pcs_ver')} 3`,
                value: data.pcs_ver3 ? formatToTwoDecimalVersion(data.pcs_ver3) : null,
            },
            {
                key: 'bms_ver',
                title: t('device.bms_ver'),
                value: BMSData?.version ? formatToThreeDecimalVersion(BMSData?.version) : null,
            },
        ],
    };
};
export const getInverterProfileGEN3 = (data, t, provisionState) => {
    const inverterProfile = {
        title: 'Standalone Inverter',
        content: [
            {
                key: 'device_id',
                title: t('device.serial_no'),
                value: data.device_id,
            },
            {
                key: 'ems_ver',
                title: t('device.ems_ver'),
                value: data.ems_ver,
            },
            {
                key: 'pcs_ver',
                title: t('device.pcs_ver'),
                value: data.pcs_ver,
            },
            {
                key: 'bms_ver1',
                title: `${t('device.bms_ver')}1`,
                value: data.bms_ver1,
            },
            {
                key: 'bms_ver2',
                title: `${t('device.bms_ver')}2`,
                value: data.bms_ver2,
            },
            {
                key: 'bms_ver3',
                title: `${t('device.bms_ver')}3`,
                value: data.bms_ver3,
            },
            {
                key: 'energy_control',
                title: t('device.operation_mode'),
                value: t(`${data.energy_policy_nm}`),
            },
            {
                key: 'pcs_mode',
                title: t('device.pcs_mode'),
                value: t(`${data.pcs_opmode1_nm}`),
            },
        ],
    };
    if (provisionState) {
        inverterProfile.content.push({
            key: 'provision_state',
            title: 'GENI Site Creation',
            value: provisionState,
        });
    }
    return inverterProfile;
};
export const getACCoupledESSProfile = (data, t) => {
    const essProfileInfo = data.map((essInfo, index) => {
        return {
            title: data.length > 1 ? `ESS ${index + 1}` : 'ESS',
            content: [
                {
                    key: 'device_id',
                    title: t('device.serial_no'),
                    value: essInfo.device_id,
                },
                {
                    key: 'ems_ver',
                    title: t('device.ems_ver'),
                    value: essInfo.ems_ver,
                },
                {
                    key: 'pcs_ver',
                    title: t('device.pcs_ver'),
                    value: essInfo.pcs_ver,
                },
                {
                    key: 'bms_ver',
                    title: `${t('device.bms_ver')}`,
                    value: essInfo.bms_ver1,
                },
                {
                    key: 'energy_control',
                    title: t('device.operation_mode'),
                    value: t(`${essInfo.energy_control_nm}`),
                },
                {
                    key: 'pcs_mode',
                    title: t('device.pcs_mode'),
                    value: t(`${essInfo.pcs_opmode1_nm}`),
                },
            ],
        };
    });
    return essProfileInfo;
};
export const getACCombinerProfile = (data, ACDeviceType, t) => {
    return {
        title: 'AC Combiner',
        content: [
            {
                key: 'device_id',
                title: t('device.serial_no'),
                value: data.device_id,
            },
            {
                key: 'ems_ver',
                title: t('device.ems_ver'),
                value: data.ems_ver,
            },
            {
                key: 'gem_ver',
                title: 'GEM Version',
                value: Helper.convertDecimalToHex(Number(data.gem_ver), 8),
            },
            {
                key: 'operation_mode',
                title: t('device.operation_mode'),
                value: getEnergyControlValueForAC(ACDeviceType, t, data),
            },
            {
                key: 'pcs_mode',
                title: t('device.pcs_mode'),
                value: t(`${data.pcs_opmode1_nm}`),
            },
        ],
    };
};
