import { computed, defineComponent, reactive, ref } from 'vue';
import { gsap } from 'gsap';
import { Constant } from '@hems/util';
export default defineComponent({
    name: 'LanguageSelector',
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: String,
            default: 'en',
        },
    },
    setup(props, { emit }) {
        const lenLayer = ref(null);
        const languageEl = ref(null);
        const { languages } = Constant;
        const state = reactive({
            language: props.modelValue,
            selected: computed(() => languages.find((item) => item.value === props.modelValue)),
            options: computed(() => languages.filter((item) => item.value !== props.modelValue)),
            dropbox: {
                onLenDrop: false,
                onLenLayer: false,
                show: false,
            },
        });
        const onSelectLanguage = (selectedLanguage) => {
            state.language = selectedLanguage.value;
            emit('update:modelValue', selectedLanguage.value);
            closeDropBox();
        };
        const toggleLocaleDropbox = () => {
            if (state.dropbox.show) {
                closeDropBox();
                return;
            }
            showDropbox();
        };
        function clickOtherLayer(e) {
            const target = e.target;
            if (target.closest('#language-selector') !== languageEl.value)
                closeDropBox();
        }
        const showDropbox = () => {
            window.addEventListener('click', clickOtherLayer);
            state.dropbox.show = true;
            state.dropbox.onLenDrop = true;
            setTimeout(() => {
                state.dropbox.onLenLayer = true;
            }, 100);
            const height = 40 * (languages.length - 1) + 20;
            gsap.to([lenLayer.value], { height: `${height}px`, duration: 0.3 });
        };
        const closeDropBox = () => {
            window.removeEventListener('click', clickOtherLayer);
            state.dropbox.show = false;
            state.dropbox.onLenLayer = false;
            setTimeout(() => {
                state.dropbox.onLenDrop = false;
            }, 100);
            gsap.to([lenLayer.value], { height: '0', duration: 0.3 });
        };
        return {
            state,
            onSelectLanguage,
            toggleLocaleDropbox,
            closeDropBox,
            lenLayer,
            languageEl,
        };
    },
});
