export const ERROR_HISTORY_GRID_TYPE = {
    ALL: 'all',
    SITE: 'site',
};
export const ERROR_LEVEL = {
    FAULT: 'F',
    WARNING: 'W',
    ALARM: 'A',
};
export const ERROR_LEVEL_DISPLAY_TEXT = {
    FAULT: 'Fault',
    WARNING: 'Warning',
    ALARM: 'Alarm',
};
export const ERROR_LEVEL_SELECTOR_OPTIONS = [
    { code: ERROR_LEVEL_DISPLAY_TEXT.FAULT.toLocaleLowerCase(), name: ERROR_LEVEL_DISPLAY_TEXT.FAULT },
    { code: ERROR_LEVEL_DISPLAY_TEXT.WARNING.toLocaleLowerCase(), name: ERROR_LEVEL_DISPLAY_TEXT.WARNING },
    { code: ERROR_LEVEL_DISPLAY_TEXT.ALARM.toLocaleLowerCase(), name: ERROR_LEVEL_DISPLAY_TEXT.ALARM },
];
export const ERROR_PREFIX = {
    GRID: 'G',
    SOLAR: 'S',
    MICRO_INVERTER: 'M',
    AC_COMBINER: 'A',
    DC_OPTIMIZER: 'O',
    /** ESS - Inverter */
    INVERTER: 'P',
    /** ESS - BDC */
    BDC: 'D',
    /** ESS - EMS 또는 G4 HUB - EMS */
    EMS: 'E',
    /** ESS - BMS */
    BMS: 'B',
    HUB: 'H',
    CLOUD: 'C',
};
export const ERROR_STATUS_TYPE = {
    ERROR: 'E',
    WARNING: 'W',
};
export const ERROR_RESOLVED_FILTER = {
    CURRENT: 'current',
    RESOLVED: 'resolved',
};
