import { defineComponent, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { SettingTable, SettingTableRow } from '@hems/component';
import { ModuleInfoModelTextMap, OPERATION_MODE_CODE, POWER_CONTROL_RANGE, VctrlGainUsageTextMap, } from '@hems/util/src/constant/control';
import PopupWrapper from './PopupWrapper.vue';
import { BasicInput } from '../inputs';
export default defineComponent({
    name: 'ControlDataPopup',
    components: {
        PopupWrapper,
        SettingTable,
        SettingTableRow,
        BasicInput,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        cancelText: {
            type: String,
            default: 'Cancel',
        },
        data: {
            type: Object,
            required: true,
        },
        controlType: {
            type: String,
            required: true,
        },
    },
    emits: ['close'],
    async setup(props, { emit }) {
        const { t } = useI18n();
        const state = reactive({
            data: { ...props.data },
        });
        const modeText = t(`code.changeoperationmodegen3cd.${props.data.mode}`);
        const powerControlPointText = t(`code.powercontrolpointgen3cd.${props.data.power_control_point}`);
        const durationText = t(`code.reversessh_duration_gen3_cd.${props.data.duration}`);
        const bms1Text = t(`code.releasebmsprotectiongen3cd.${props.data.bms1}`);
        const bms2Text = t(`code.releasebmsprotectiongen3cd.${props.data.bms2}`);
        const bms3Text = t(`code.releasebmsprotectiongen3cd.${props.data.bms3}`);
        const commandText = t(`code.dcoptcontrolcmdcd.${props.data.command}`);
        const moduleInfoModelText = ModuleInfoModelTextMap[props.data.model];
        const vctrlGainUsageText = VctrlGainUsageTextMap[props.data.vctrl_gain_usage];
        const close = () => {
            emit('close');
        };
        return {
            state,
            close,
            OPERATION_MODE_CODE,
            POWER_CONTROL_RANGE,
            modeText,
            powerControlPointText,
            durationText,
            bms1Text,
            bms2Text,
            bms3Text,
            commandText,
            moduleInfoModelText,
            vctrlGainUsageText,
        };
    },
});
