import _ from 'lodash';
import { AC_DEVICE_TYPE, GEN_TYPE, GRAPH_GROUP_CODE, GRAPH_Y_AXIS_UNIT } from '@hems/util/src/constant';
import { getHourKeysByGenType, getMinuteKeysByGenType } from './graphKeys';
const filterName = {
    [GEN_TYPE.GEN2]: {
        user_soc_avg: 'device.soc',
    },
    [GEN_TYPE.FOX_ESS_H3]: {
        inverter_pw: 'Total PCS Power',
        load_pw: 'Total Load Power',
        meter_pwfactor: 'Total Power Factor',
        meter_active_pw: 'Total Active Power',
        meter_reactive_pw: 'Total Reactive Power',
    },
    [GEN_TYPE.AC_SYS]: {
        real_soc_avg: 'device.real_soc',
        soc1: 'device.real_soc',
        soc2: 'device.real_soc',
        soc3: 'device.real_soc',
        load_pw: 'device.load_consumed',
        load_pwh_day: 'device.load_energy_consumed',
        meter_freq: 'device.grid_frequency',
        load_wh: 'device.load_energy_consumed',
        meter_v_r: 'device.grid_voltage_l1',
        meter_v_s: 'device.grid_voltage_l2',
        meter_i_r: 'device.grid_current_l1',
        meter_i_s: 'device.grid_current_l2',
    },
};
const filterDefaultChecked = {
    [GEN_TYPE.FOX_ESS]: {
        pv_pw1: true,
        pv_pw2: true,
        pv_pw3: true,
    },
    [GEN_TYPE.FOX_ESS_H3]: {
        dc_link_v: true,
        pv_pw1: true,
        pv_pw2: true,
        pv_pw3: true,
        inverter_pw: true,
        meter_pwfactor: true,
        meter_active_pw: true,
        meter_reactive_pw: true,
    },
};
const commonPostfix = {
    cell_avg_t1: ' #1',
    cell_min_t1: ' #1',
    cell_max_t1: ' #1',
    cell_avg_v1: ' #1',
    cell_min_v1: ' #1',
    cell_max_v1: ' #1',
    rack_v1: ' #1',
    rack_i1: ' #1',
    soh1: ' #1',
};
const filterPostfix = {
    [GEN_TYPE.GEN3]: commonPostfix,
    [GEN_TYPE.AC_SYS]: commonPostfix,
};
const filterNameHandlerByGenType = (genType, code, defaultValue) => {
    return filterName[genType]?.[code] ?? defaultValue;
};
const filterDefaultCheckedHandlerByGenType = (genType, code, defaultValue) => {
    return filterDefaultChecked[genType]?.[code] ?? defaultValue;
};
const filterPostfixHandlerByGenType = (genType, code, defaultValue) => {
    return filterPostfix[genType]?.[code] ?? defaultValue;
};
const getPowerFilters = (genType) => {
    return [
        {
            code: 'inverter_pw',
            name: filterNameHandlerByGenType(genType, 'inverter_pw', 'device.pcs_pw'),
            altName: 'PCS Power',
            yAxis: GRAPH_Y_AXIS_UNIT.WATT,
            defaultChecked: filterDefaultCheckedHandlerByGenType(genType, 'inverter_pw', false),
        },
        { code: 'inverter_pw_r', name: 'PCS Power R', altName: 'PCS Power R', yAxis: GRAPH_Y_AXIS_UNIT.WATT },
        { code: 'inverter_pw_s', name: 'PCS Power S', altName: 'PCS Power S', yAxis: GRAPH_Y_AXIS_UNIT.WATT },
        { code: 'inverter_pw_t', name: 'PCS Power T', altName: 'PCS Power T', yAxis: GRAPH_Y_AXIS_UNIT.WATT },
        { code: 'pv_pw', name: 'device.pv_pw', altName: 'PV Power', yAxis: GRAPH_Y_AXIS_UNIT.WATT, defaultChecked: true },
        {
            code: 'bt_pw',
            name: 'device.batt_pw',
            altName: 'Battery Power',
            yAxis: GRAPH_Y_AXIS_UNIT.WATT,
            defaultChecked: true,
        },
        {
            code: 'load_pw',
            name: filterNameHandlerByGenType(genType, 'load_pw', 'device.load_pw'),
            altName: genType === GEN_TYPE.AC_SYS ? 'Load Consumed' : 'Load Power',
            yAxis: GRAPH_Y_AXIS_UNIT.WATT,
            defaultChecked: true,
        },
        { code: 'load_pw_r', name: 'Load Power R', altName: 'Load Power R', yAxis: GRAPH_Y_AXIS_UNIT.WATT },
        { code: 'load_pw_s', name: 'Load Power S', altName: 'Load Power S', yAxis: GRAPH_Y_AXIS_UNIT.WATT },
        { code: 'load_pw_t', name: 'Load Power T', altName: 'Load Power T', yAxis: GRAPH_Y_AXIS_UNIT.WATT },
        {
            code: 'meter_active_pw',
            name: 'device.grid_pw',
            altName: 'Grid Power',
            yAxis: GRAPH_Y_AXIS_UNIT.WATT,
            defaultChecked: true,
        },
        {
            code: 'grid_pw',
            name: 'device.grid_pw',
            altName: 'Grid Power',
            yAxis: GRAPH_Y_AXIS_UNIT.WATT,
            defaultChecked: true,
        },
        {
            code: 'total_pv_pw',
            name: 'device.total_pv_pw',
            altName: 'Total PV Power',
            yAxis: GRAPH_Y_AXIS_UNIT.WATT,
            defaultChecked: true,
        },
        {
            code: 'accb_power_total',
            name: 'device.pv_produced',
            altName: 'PV Produced',
            yAxis: GRAPH_Y_AXIS_UNIT.WATT,
            defaultChecked: true,
        },
    ];
};
const energyMinuteFilters = (genType) => {
    return [
        {
            code: 'grid_fdin_pwh_day',
            name: 'device.feedin_amn',
            altName: 'Feed-In Amount',
            yAxis: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
        },
        {
            code: 'grid_pur_pwh_day',
            name: 'device.demand_amn',
            altName: 'Demand Amount',
            yAxis: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
        },
        {
            code: 'load_pwh_day',
            name: filterNameHandlerByGenType(genType, 'load_pwh_day', 'device.load_amn'),
            altName: genType === GEN_TYPE.AC_SYS ? 'Load Energy Consumed' : 'Load Amount',
            yAxis: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
            defaultChecked: true,
        },
        {
            code: 'pv_pwh_day',
            name: 'device.pv_gen_amn',
            altName: 'PV Generation Amount',
            yAxis: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
            defaultChecked: true,
        },
        {
            code: 'pcs_fd_pwh_day',
            name: 'device.inverter_output_amn',
            altName: 'Inverter Output Amount',
            yAxis: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
        },
        {
            code: 'pcs_pch_pwh_day',
            name: 'device.inverter_input_amn',
            altName: 'Inverter Input Amount',
            yAxis: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
        },
        {
            code: 'bt_chrg_pwh_day',
            name: 'device.batt_charge_amn',
            altName: 'Battery Charge Amount',
            yAxis: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
        },
        {
            code: 'bt_dchrg_pwh_day',
            name: 'device.batt_discharge_amn',
            altName: 'Battery Discharge Amount',
            yAxis: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
        },
        {
            code: 'total_pv_pwh_day',
            name: 'device.total_pv_gen_amn',
            altName: 'Total PV Generation Amount',
            yAxis: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
            defaultChecked: true,
        },
        {
            code: 'accb_power_accum',
            name: 'device.pv_energy_produced',
            altName: 'PV Energy Produced',
            yAxis: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
            defaultChecked: true,
        },
    ];
};
const acCombinerFilters = [
    {
        code: 'accb_voltage_single',
        name: 'AC Combiner Voltage',
        altName: 'AC Combiner Voltage',
        unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        defaultChecked: true,
    },
    {
        code: 'accb_current_single',
        name: 'AC Combiner Current',
        altName: 'AC Combiner Current',
        unit: GRAPH_Y_AXIS_UNIT.AMPERE,
        yAxis: GRAPH_Y_AXIS_UNIT.AMPERE,
        defaultChecked: true,
    },
    {
        code: 'accb_frequency_single',
        name: 'device.frequency',
        altName: 'Frequency',
        unit: GRAPH_Y_AXIS_UNIT.HERTZ,
        yAxis: GRAPH_Y_AXIS_UNIT.HERTZ,
        defaultChecked: true,
    },
];
const getInverterFilters = (genType) => {
    return [
        {
            code: 'inverter_v',
            name: 'device.grid_voltage',
            altName: 'Inverter Voltage',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'inverter_v_r',
            name: 'Inverter Voltage R',
            altName: 'Inverter Voltage R',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            defaultChecked: true,
        },
        {
            code: 'inverter_v_s',
            name: 'Inverter Voltage S',
            altName: 'Inverter Voltage S',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'inverter_v_t',
            name: 'Inverter Voltage T',
            altName: 'Inverter Voltage T',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'inverter_i',
            name: 'device.grid_curr',
            altName: 'Inverter Current',
            unit: GRAPH_Y_AXIS_UNIT.AMPERE,
            yAxis: GRAPH_Y_AXIS_UNIT.AMPERE,
        },
        {
            code: 'inverter_i_r',
            name: 'Inverter Current R',
            altName: 'Inverter Current R',
            unit: GRAPH_Y_AXIS_UNIT.AMPERE,
            yAxis: GRAPH_Y_AXIS_UNIT.AMPERE,
            defaultChecked: true,
        },
        {
            code: 'inverter_i_s',
            name: 'Inverter Current S',
            altName: 'Inverter Current S',
            unit: GRAPH_Y_AXIS_UNIT.AMPERE,
            yAxis: GRAPH_Y_AXIS_UNIT.AMPERE,
        },
        {
            code: 'inverter_i_t',
            name: 'Inverter Current T',
            altName: 'Inverter Current T',
            unit: GRAPH_Y_AXIS_UNIT.AMPERE,
            yAxis: GRAPH_Y_AXIS_UNIT.AMPERE,
        },
        {
            code: 'inverter_freq',
            name: 'device.frequency',
            altName: 'Frequency',
            unit: GRAPH_Y_AXIS_UNIT.HERTZ,
            yAxis: GRAPH_Y_AXIS_UNIT.HERTZ,
        },
        {
            code: 'inverter_freq_r',
            name: 'Frequency R',
            altName: 'Frequency R',
            unit: GRAPH_Y_AXIS_UNIT.HERTZ,
            yAxis: GRAPH_Y_AXIS_UNIT.HERTZ,
            defaultChecked: true,
        },
        {
            code: 'inverter_freq_s',
            name: 'Frequency S',
            altName: 'Frequency S',
            unit: GRAPH_Y_AXIS_UNIT.HERTZ,
            yAxis: GRAPH_Y_AXIS_UNIT.HERTZ,
        },
        {
            code: 'inverter_freq_t',
            name: 'Frequency T',
            altName: 'Frequency T',
            unit: GRAPH_Y_AXIS_UNIT.HERTZ,
            yAxis: GRAPH_Y_AXIS_UNIT.HERTZ,
        },
        {
            code: 'dc_link_v',
            name: 'device.dc_link',
            altName: 'DC Link Voltage',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            defaultChecked: filterDefaultCheckedHandlerByGenType(genType, 'dc_link_v', false),
        },
        {
            code: 'bt_v',
            name: 'BDC Voltage',
            altName: 'BDC Voltage',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        // @TODO: ac system에서 필요한 항목인지 확인 필요
        {
            code: 'bat_voltage_total',
            name: 'BDC Voltage',
            altName: 'BDC Voltage',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            defaultChecked: true,
        },
    ];
};
const getPvFilters = (genType) => {
    return [
        {
            code: 'pv_v1',
            name: 'PV1 Voltage',
            altName: 'PV1 Voltage',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'pv_i1',
            name: 'PV1 Current',
            altName: 'PV1 Current',
            unit: GRAPH_Y_AXIS_UNIT.AMPERE,
            yAxis: GRAPH_Y_AXIS_UNIT.AMPERE,
        },
        {
            code: 'pv_pw1',
            name: 'PV1 Power',
            altName: 'PV1 Power',
            unit: GRAPH_Y_AXIS_UNIT.WATT,
            yAxis: GRAPH_Y_AXIS_UNIT.WATT,
            defaultChecked: filterDefaultCheckedHandlerByGenType(genType, 'pv_pw1', false),
        },
        {
            code: 'pv_v2',
            name: 'PV2 Voltage',
            altName: 'PV2 Voltage',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'pv_i2',
            name: 'PV2 Current',
            altName: 'PV2 Current',
            unit: GRAPH_Y_AXIS_UNIT.AMPERE,
            yAxis: GRAPH_Y_AXIS_UNIT.AMPERE,
        },
        {
            code: 'pv_pw2',
            name: 'PV2 Power',
            altName: 'PV2 Power',
            unit: GRAPH_Y_AXIS_UNIT.WATT,
            yAxis: GRAPH_Y_AXIS_UNIT.WATT,
            defaultChecked: filterDefaultCheckedHandlerByGenType(genType, 'pv_pw2', false),
        },
        {
            code: 'pv_v3',
            name: 'PV3 Voltage',
            altName: 'PV3 Voltage',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'pv_i3',
            name: 'PV3 Current',
            altName: 'PV3 Current',
            unit: GRAPH_Y_AXIS_UNIT.AMPERE,
            yAxis: GRAPH_Y_AXIS_UNIT.AMPERE,
        },
        {
            code: 'pv_pw3',
            name: 'PV3 Power',
            altName: 'PV3 Power',
            unit: GRAPH_Y_AXIS_UNIT.WATT,
            yAxis: GRAPH_Y_AXIS_UNIT.WATT,
            defaultChecked: filterDefaultCheckedHandlerByGenType(genType, 'pv_pw3', false),
        },
    ];
};
const getMeterFilters = (genType) => {
    return [
        {
            code: 'meter_v',
            name: 'device.voltage',
            altName: 'Voltage',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'meter_v_r',
            name: filterNameHandlerByGenType(genType, 'meter_v_r', 'Voltage R'),
            altName: 'Voltage R',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            defaultChecked: true,
        },
        {
            code: 'meter_v_s',
            name: filterNameHandlerByGenType(genType, 'meter_v_s', 'Voltage S'),
            altName: 'Voltage S',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'meter_v_t',
            name: 'Voltage T',
            altName: 'Voltage T',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'meter_i',
            name: 'device.current',
            altName: 'Current',
            unit: GRAPH_Y_AXIS_UNIT.AMPERE,
            yAxis: GRAPH_Y_AXIS_UNIT.AMPERE,
        },
        {
            code: 'meter_i_r',
            name: filterNameHandlerByGenType(genType, 'meter_i_r', 'Current R'),
            altName: 'Current R',
            unit: GRAPH_Y_AXIS_UNIT.AMPERE,
            yAxis: GRAPH_Y_AXIS_UNIT.AMPERE,
            defaultChecked: true,
        },
        {
            code: 'meter_i_s',
            name: filterNameHandlerByGenType(genType, 'meter_i_s', 'Current S'),
            altName: 'Current S',
            unit: GRAPH_Y_AXIS_UNIT.AMPERE,
            yAxis: GRAPH_Y_AXIS_UNIT.AMPERE,
        },
        {
            code: 'meter_i_t',
            name: 'Current T',
            altName: 'Current T',
            unit: GRAPH_Y_AXIS_UNIT.AMPERE,
            yAxis: GRAPH_Y_AXIS_UNIT.AMPERE,
        },
        {
            code: 'meter_freq',
            name: filterNameHandlerByGenType(genType, 'meter_freq', 'device.frequency'),
            altName: genType === GEN_TYPE.AC_SYS ? 'Grid Frequency' : 'Frequency',
            unit: GRAPH_Y_AXIS_UNIT.HERTZ,
            yAxis: GRAPH_Y_AXIS_UNIT.HERTZ,
        },
        {
            code: 'meter_freq_r',
            name: 'Frequency R',
            altName: 'Frequency R',
            unit: GRAPH_Y_AXIS_UNIT.HERTZ,
            yAxis: GRAPH_Y_AXIS_UNIT.HERTZ,
            defaultChecked: true,
        },
        {
            code: 'meter_freq_s',
            name: 'Frequency S',
            altName: 'Frequency S',
            unit: GRAPH_Y_AXIS_UNIT.HERTZ,
            yAxis: GRAPH_Y_AXIS_UNIT.HERTZ,
        },
        {
            code: 'meter_freq_t',
            name: 'Frequency T',
            altName: 'Frequency T',
            unit: GRAPH_Y_AXIS_UNIT.HERTZ,
            yAxis: GRAPH_Y_AXIS_UNIT.HERTZ,
        },
        {
            code: 'meter_pwfactor',
            name: filterNameHandlerByGenType(genType, 'meter_pwfactor', 'device.power_factor'),
            altName: 'Power Factor',
            unit: GRAPH_Y_AXIS_UNIT.FACTOR,
            yAxis: GRAPH_Y_AXIS_UNIT.FACTOR,
            defaultChecked: filterDefaultCheckedHandlerByGenType(genType, 'meter_pwfactor', false),
        },
        {
            code: 'meter_pwfactor_r',
            name: 'Power Factor R',
            altName: 'Power Factor R',
            unit: GRAPH_Y_AXIS_UNIT.FACTOR,
            yAxis: GRAPH_Y_AXIS_UNIT.FACTOR,
        },
        {
            code: 'meter_pwfactor_s',
            name: 'Power Factor S',
            altName: 'Power Factor S',
            unit: GRAPH_Y_AXIS_UNIT.FACTOR,
            yAxis: GRAPH_Y_AXIS_UNIT.FACTOR,
        },
        {
            code: 'meter_pwfactor_t',
            name: 'Power Factor T',
            altName: 'Power Factor T',
            unit: GRAPH_Y_AXIS_UNIT.FACTOR,
            yAxis: GRAPH_Y_AXIS_UNIT.FACTOR,
        },
        {
            code: 'meter_active_pw',
            name: filterNameHandlerByGenType(genType, 'meter_active_pw', 'device.active_pw'),
            altName: 'Active Power',
            unit: GRAPH_Y_AXIS_UNIT.WATT,
            yAxis: GRAPH_Y_AXIS_UNIT.WATT,
            defaultChecked: filterDefaultCheckedHandlerByGenType(genType, 'meter_active_pw', false),
        },
        {
            code: 'meter_active_pw_r',
            name: 'Active Power R',
            altName: 'Active Power R',
            unit: GRAPH_Y_AXIS_UNIT.WATT,
            yAxis: GRAPH_Y_AXIS_UNIT.WATT,
        },
        {
            code: 'meter_active_pw_s',
            name: 'Active Power S',
            altName: 'Active Power S',
            unit: GRAPH_Y_AXIS_UNIT.WATT,
            yAxis: GRAPH_Y_AXIS_UNIT.WATT,
        },
        {
            code: 'meter_active_pw_t',
            name: 'Active Power T',
            altName: 'Active Power T',
            unit: GRAPH_Y_AXIS_UNIT.WATT,
            yAxis: GRAPH_Y_AXIS_UNIT.WATT,
        },
        {
            code: 'meter_reactive_pw',
            name: filterNameHandlerByGenType(genType, 'meter_reactive_pw', 'device.reactive_pw'),
            altName: 'Reactive Power',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE_AMPERE_REACTIVE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE_AMPERE_REACTIVE,
            defaultChecked: filterDefaultCheckedHandlerByGenType(genType, 'meter_reactive_pw', false),
        },
        {
            code: 'meter_reactive_pw_r',
            name: 'Reactive Power R',
            altName: 'Reactive Power R',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE_AMPERE_REACTIVE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE_AMPERE_REACTIVE,
        },
        {
            code: 'meter_reactive_pw_s',
            name: 'Reactive Power S',
            altName: 'Reactive Power S',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE_AMPERE_REACTIVE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE_AMPERE_REACTIVE,
        },
        {
            code: 'meter_reactive_pw_t',
            name: 'Reactive Power T',
            altName: 'Reactive Power T',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE_AMPERE_REACTIVE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE_AMPERE_REACTIVE,
        },
    ];
};
const getBatteryFilters = (genType) => {
    return [
        {
            code: 'user_soc_avg',
            name: filterNameHandlerByGenType(genType, 'user_soc_avg', 'control.user_soc'),
            altName: 'User SOC',
            unit: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
            defaultChecked: true,
        },
        {
            code: 'rack_user_soc1',
            name: 'control.user_soc',
            altName: 'User SOC',
            postfix: ' #1',
            unit: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
            defaultChecked: true,
        },
        {
            code: 'rack_user_soc2',
            name: 'control.user_soc',
            altName: 'User SOC',
            postfix: ' #2',
            unit: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
            defaultChecked: true,
        },
        {
            code: 'rack_user_soc3',
            name: 'control.user_soc',
            altName: 'User SOC',
            postfix: ' #3',
            unit: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
            defaultChecked: true,
        },
        {
            code: 'real_soc_avg',
            name: 'device.real_soc',
            altName: 'Real SOC',
            unit: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
            defaultChecked: true,
        },
        {
            code: 'cell_avg_t1',
            name: 'device.avg_temp',
            altName: 'Avg. Temperature',
            postfix: filterPostfixHandlerByGenType(genType, 'cell_avg_t1', ''),
            unit: GRAPH_Y_AXIS_UNIT.CELSIUS,
            yAxis: GRAPH_Y_AXIS_UNIT.CELSIUS,
        },
        {
            code: 'cell_avg_t2',
            name: 'device.avg_temp',
            altName: 'Avg. Temperature',
            postfix: ' #2',
            unit: GRAPH_Y_AXIS_UNIT.CELSIUS,
            yAxis: GRAPH_Y_AXIS_UNIT.CELSIUS,
        },
        {
            code: 'cell_avg_t3',
            name: 'device.avg_temp',
            altName: 'Avg. Temperature',
            postfix: ' #3',
            unit: GRAPH_Y_AXIS_UNIT.CELSIUS,
            yAxis: GRAPH_Y_AXIS_UNIT.CELSIUS,
        },
        {
            code: 'cell_min_t1',
            name: 'device.min_temp',
            altName: 'Cell Temp. Min',
            postfix: filterPostfixHandlerByGenType(genType, 'cell_min_t1', ''),
            unit: GRAPH_Y_AXIS_UNIT.CELSIUS,
            yAxis: GRAPH_Y_AXIS_UNIT.CELSIUS,
        },
        {
            code: 'cell_min_t2',
            name: 'device.min_temp',
            altName: 'Cell Temp. Min',
            postfix: ' #2',
            unit: GRAPH_Y_AXIS_UNIT.CELSIUS,
            yAxis: GRAPH_Y_AXIS_UNIT.CELSIUS,
        },
        {
            code: 'cell_min_t3',
            name: 'device.min_temp',
            altName: 'Cell Temp. Min',
            postfix: ' #3',
            unit: GRAPH_Y_AXIS_UNIT.CELSIUS,
            yAxis: GRAPH_Y_AXIS_UNIT.CELSIUS,
        },
        {
            code: 'cell_max_t1',
            name: 'device.max_temp',
            altName: 'Cell Temp. Max',
            postfix: filterPostfixHandlerByGenType(genType, 'cell_max_t1', ''),
            unit: GRAPH_Y_AXIS_UNIT.CELSIUS,
            yAxis: GRAPH_Y_AXIS_UNIT.CELSIUS,
        },
        {
            code: 'cell_max_t2',
            name: 'device.max_temp',
            altName: 'Cell Temp. Max',
            postfix: ' #2',
            unit: GRAPH_Y_AXIS_UNIT.CELSIUS,
            yAxis: GRAPH_Y_AXIS_UNIT.CELSIUS,
        },
        {
            code: 'cell_max_t3',
            name: 'device.max_temp',
            altName: 'Cell Temp. Max',
            postfix: ' #3',
            unit: GRAPH_Y_AXIS_UNIT.CELSIUS,
            yAxis: GRAPH_Y_AXIS_UNIT.CELSIUS,
        },
        {
            code: 'cell_avg_v1',
            name: 'device.avg_voltage',
            altName: 'Cell Voltage Average',
            postfix: filterPostfixHandlerByGenType(genType, 'cell_avg_v1', ''),
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'cell_avg_v2',
            name: 'device.avg_voltage',
            altName: 'Cell Voltage Average',
            postfix: ' #2',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'cell_avg_v3',
            name: 'device.avg_voltage',
            altName: 'Cell Voltage Average',
            postfix: ' #3',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'cell_min_v1',
            name: 'device.min_voltage',
            altName: 'Cell Voltage Min',
            postfix: filterPostfixHandlerByGenType(genType, 'cell_min_v1', ''),
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'cell_min_v2',
            name: 'device.min_voltage',
            altName: 'Cell Voltage Min',
            postfix: ' #2',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'cell_min_v3',
            name: 'device.min_voltage',
            altName: 'Cell Voltage Min',
            postfix: ' #3',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'cell_max_v1',
            name: 'device.max_voltage',
            altName: 'Cell Voltage Max',
            postfix: filterPostfixHandlerByGenType(genType, 'cell_max_v1', ''),
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'cell_max_v2',
            name: 'device.max_voltage',
            altName: 'Cell Voltage Max',
            postfix: ' #2',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'cell_max_v3',
            name: 'device.max_voltage',
            altName: 'Cell Voltage Max',
            postfix: ' #3',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'soc1',
            name: filterNameHandlerByGenType(genType, 'soc1', 'device.soc'),
            altName: 'Real SOC',
            postfix: ' #1',
            unit: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
            defaultChecked: true,
        },
        {
            code: 'soc2',
            name: filterNameHandlerByGenType(genType, 'soc2', 'device.soc'),
            altName: 'Real SOC',
            postfix: ' #2',
            unit: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
            defaultChecked: true,
        },
        {
            code: 'soc3',
            name: filterNameHandlerByGenType(genType, 'soc3', 'device.soc'),
            altName: 'Real SOC',
            postfix: ' #3',
            unit: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
            defaultChecked: true,
        },
        {
            code: 'soh1',
            name: 'control.soh',
            altName: 'SOH',
            postfix: filterPostfixHandlerByGenType(genType, 'soh1', ''),
            unit: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
        },
        {
            code: 'soh2',
            name: 'control.soh',
            altName: 'SOH',
            postfix: ' #2',
            unit: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
        },
        {
            code: 'soh3',
            name: 'control.soh',
            altName: 'SOH',
            postfix: ' #3',
            unit: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.PERCENTAGE,
        },
        {
            code: 'rack_v1',
            name: 'device.rack_voltage',
            altName: 'Rack Voltage',
            postfix: filterPostfixHandlerByGenType(genType, 'rack_v1', ''),
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'rack_v2',
            name: 'device.rack_voltage',
            altName: 'Rack Voltage',
            postfix: ' #2',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'rack_v3',
            name: 'device.rack_voltage',
            altName: 'Rack Voltage',
            postfix: ' #3',
            unit: GRAPH_Y_AXIS_UNIT.VOLTAGE,
            yAxis: GRAPH_Y_AXIS_UNIT.VOLTAGE,
        },
        {
            code: 'rack_i1',
            name: 'device.rack_curr',
            altName: 'Rack Current',
            postfix: filterPostfixHandlerByGenType(genType, 'rack_i1', ''),
            unit: GRAPH_Y_AXIS_UNIT.AMPERE,
            yAxis: GRAPH_Y_AXIS_UNIT.AMPERE,
        },
        {
            code: 'rack_i2',
            name: 'device.rack_curr',
            altName: 'Rack Current',
            postfix: ' #2',
            unit: GRAPH_Y_AXIS_UNIT.AMPERE,
            yAxis: GRAPH_Y_AXIS_UNIT.AMPERE,
        },
        {
            code: 'rack_i3',
            name: 'device.rack_curr',
            altName: 'Rack Current',
            postfix: ' #3',
            unit: GRAPH_Y_AXIS_UNIT.AMPERE,
            yAxis: GRAPH_Y_AXIS_UNIT.AMPERE,
        },
    ];
};
const energyHourFilters = (genType) => {
    return [
        {
            code: 'grid_tr_wh',
            name: 'device.feedin_amn',
            altName: 'Feed-In Amount',
            unit: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
            yAxis: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
        },
        {
            code: 'grid_ob_wh',
            name: 'device.demand_amn',
            altName: 'Demand Amount',
            unit: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
            yAxis: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
        },
        {
            code: 'load_wh',
            name: filterNameHandlerByGenType(genType, 'load_wh', 'device.load_amn'),
            altName: genType === GEN_TYPE.AC_SYS ? 'Load Energy Consumed' : 'Load Amount',
            unit: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
            yAxis: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
        },
        {
            code: 'pv_wh',
            name: 'device.pv_gen_amn',
            altName: 'PV Generation Amount',
            unit: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
            yAxis: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
        },
        {
            code: 'pcs_tr_wh',
            name: 'device.inverter_output_amn',
            altName: 'Inverter Output Amount',
            unit: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
            yAxis: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
        },
        {
            code: 'pcs_cons_wh',
            name: 'device.inverter_input_amn',
            altName: 'Inverter Input Amount',
            unit: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
            yAxis: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
        },
        {
            code: 'bt_chrg_wh',
            name: 'device.batt_charge_amn',
            altName: 'Battery Charge Amount',
            unit: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
            yAxis: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
        },
        {
            code: 'bt_dchrg_wh',
            name: 'device.batt_discharge_amn',
            altName: 'Battery Discharge Amount',
            unit: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
            yAxis: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
        },
        {
            code: 'total_pv_wh',
            name: 'device.total_pv_gen_amn',
            altName: 'Total PV Generation Amount',
            unit: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
            yAxis: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
        },
        {
            code: 'accb_power_wh',
            name: filterNameHandlerByGenType(genType, 'accb_power_wh', 'device.pv_energy_produced'),
            altName: 'PV Energy Produced',
            unit: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
            yAxis: GRAPH_Y_AXIS_UNIT.WATT_HOUR,
        },
    ];
};
export const getPowerConfig = (keys, genType) => {
    return {
        filterGroupCode: GRAPH_GROUP_CODE.POWER,
        filterGroupName: 'device.pw_data',
        filterList: getPowerFilters(genType).filter((filter) => keys.includes(filter.code)),
    };
};
export const getEnergyMinuteConfig = (keys, genType) => {
    return {
        filterGroupCode: GRAPH_GROUP_CODE.ENERGY,
        filterGroupName: 'device.energy_data',
        filterList: energyMinuteFilters(genType).filter((filter) => keys.includes(filter.code)),
    };
};
export const getACCombinerConfig = (keys) => {
    return {
        filterGroupCode: GRAPH_GROUP_CODE.AC_COMBINER,
        filterGroupName: 'AC Combiner Data',
        filterList: acCombinerFilters.filter((filter) => keys.includes(filter.code)),
    };
};
export const getPvConfig = (keys, genType) => {
    return {
        filterGroupCode: GRAPH_GROUP_CODE.PV,
        filterGroupName: 'PV Data',
        filterList: getPvFilters(genType).filter((filter) => keys.includes(filter.code)),
    };
};
export const getInverterConfig = (keys, genType) => {
    return {
        filterGroupCode: GRAPH_GROUP_CODE.INVERTER,
        filterGroupName: 'device.inverter_data',
        filterList: getInverterFilters(genType).filter((filter) => keys.includes(filter.code)),
    };
};
export const getMeterConfig = (keys, genType) => {
    return {
        filterGroupCode: GRAPH_GROUP_CODE.METER,
        filterGroupName: genType === GEN_TYPE.AC_SYS ? 'Grid Data' : 'device.meter_data',
        filterList: getMeterFilters(genType).filter((filter) => keys.includes(filter.code)),
    };
};
// TODO: AC system 배터리 그래프 항목 순서 정렬을 위한 배열로 추후 항목 정의 완료 후 삭제 예정
const batteryKeyOrderACSystem = [
    'user_soc_avg',
    'rack_user_soc1',
    'rack_user_soc2',
    'rack_user_soc3',
    'real_soc_avg',
    'soc1',
    'soc2',
    'soc3',
    'cell_avg_t1',
    'cell_avg_t2',
    'cell_avg_t3',
    'cell_min_t1',
    'cell_min_t2',
    'cell_min_t3',
    'cell_max_t1',
    'cell_max_t2',
    'cell_max_t3',
    'cell_avg_v1',
    'cell_avg_v2',
    'cell_avg_v3',
    'cell_min_v1',
    'cell_min_v2',
    'cell_min_v3',
    'cell_max_v1',
    'cell_max_v2',
    'cell_max_v3',
    'soh1',
    'soh2',
    'soh3',
    'rack_v1',
    'rack_v2',
    'rack_v3',
    'rack_i1',
    'rack_i2',
    'rack_i3',
];
export const getBatteryConfig = (keys, genType) => {
    return {
        filterGroupCode: GRAPH_GROUP_CODE.BATTERY,
        filterGroupName: 'device.batt_data',
        filterList: genType === GEN_TYPE.AC_SYS
            ? _.sortBy(getBatteryFilters(genType).filter((filter) => keys.includes(filter.code)), (batteryItem) => batteryKeyOrderACSystem.indexOf(batteryItem.code))
            : getBatteryFilters(genType).filter((filter) => keys.includes(filter.code)),
    };
};
export const getEnergyHourConfig = (keys, genType) => {
    return {
        filterGroupCode: GRAPH_GROUP_CODE.ENERGY,
        filterGroupName: 'device.energy_data',
        filterList: energyHourFilters(genType).filter((filter) => keys.includes(filter.code)),
    };
};
export const getGraphMinuteConfig = ({ genType, roleName, ACDeviceType = AC_DEVICE_TYPE.AC_COMBINER, isACCoupled = false, }) => {
    const graphMinuteKeys = getMinuteKeysByGenType({ genType, roleName, ACDeviceType, isACCoupled });
    const commonConfig = [
        getPowerConfig(graphMinuteKeys.power, genType),
        getEnergyMinuteConfig(graphMinuteKeys.energy, genType),
        getMeterConfig(graphMinuteKeys.meter, genType),
    ];
    const ACCombinerConfig = getACCombinerConfig(graphMinuteKeys.ACCombiner);
    const pvConfig = getPvConfig(graphMinuteKeys.pv, genType);
    const inverterConfig = getInverterConfig(graphMinuteKeys.inverter, genType);
    const batteryConfig = getBatteryConfig(graphMinuteKeys.battery, genType);
    if (genType === GEN_TYPE.AC_SYS) {
        if (ACDeviceType === AC_DEVICE_TYPE.AC_COMBINER) {
            return [...commonConfig, ACCombinerConfig];
        }
        if (ACDeviceType === AC_DEVICE_TYPE.AC_ESS || ACDeviceType === AC_DEVICE_TYPE.AC_MULTI_ESS) {
            return [...commonConfig, inverterConfig, batteryConfig];
        }
        return [...commonConfig, ACCombinerConfig, inverterConfig, batteryConfig];
    }
    return [...commonConfig, pvConfig, inverterConfig, batteryConfig];
};
export const getGraphHourConfig = ({ genType, roleName, ACDeviceType, isACCoupled = false, }) => {
    const graphHourKeys = getHourKeysByGenType({ genType, roleName, ACDeviceType, isACCoupled });
    return [getEnergyHourConfig(graphHourKeys.energy, genType)];
};
