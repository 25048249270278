import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ToggleButton',
    props: {
        isSelected: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
});
