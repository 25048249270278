export var CONNECTION_STATUS;
(function (CONNECTION_STATUS) {
    CONNECTION_STATUS["online"] = "online";
    CONNECTION_STATUS["online-disconnected"] = "online-disconnected";
    CONNECTION_STATUS["offline"] = "offline";
    CONNECTION_STATUS["incomplete"] = "incomplete";
})(CONNECTION_STATUS || (CONNECTION_STATUS = {}));
export var CONNECTION_STATUS_CODE;
(function (CONNECTION_STATUS_CODE) {
    CONNECTION_STATUS_CODE["online"] = "1";
    CONNECTION_STATUS_CODE["online-disconnected"] = "0";
    CONNECTION_STATUS_CODE["offline"] = "0";
    CONNECTION_STATUS_CODE["incomplete"] = "0";
})(CONNECTION_STATUS_CODE || (CONNECTION_STATUS_CODE = {}));
export var DEVICE_STATUS;
(function (DEVICE_STATUS) {
    DEVICE_STATUS["run"] = "run";
    DEVICE_STATUS["warning"] = "warning";
    DEVICE_STATUS["error"] = "error";
    DEVICE_STATUS["incomplete"] = "incomplete";
    DEVICE_STATUS["offline"] = "offline";
})(DEVICE_STATUS || (DEVICE_STATUS = {}));
export var DEVICE_STATUS_CODE;
(function (DEVICE_STATUS_CODE) {
    DEVICE_STATUS_CODE["run"] = "0";
    DEVICE_STATUS_CODE["warning"] = "1";
    DEVICE_STATUS_CODE["error"] = "2";
    DEVICE_STATUS_CODE["incomplete"] = "4";
    DEVICE_STATUS_CODE["offline"] = "6";
})(DEVICE_STATUS_CODE || (DEVICE_STATUS_CODE = {}));
