import { defineComponent, watch, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import isEmpty from 'lodash/isEmpty';
import { ACModuleChartConfig, getSmartModuleACSystemChartYAxis, } from '@hems/container/src/forms/device/smartmodule/chartOptions';
import { SmartModuleWebACModuleChartContainer } from '@hems/container/src/highcharts/combination';
import { Helper, DateHelper, useLocale } from '@hems/util';
import { COLON_HOUR_MIN_FORMAT, GRAPH_TERM_UNIT, ONE_MINUTE_VALUE_IN_MILLISECONDS, SELECT_ALL, } from '@hems/util/src/constant';
import { isPeriod } from '@hems/util/src/helper/tsguardHelper';
export default defineComponent({
    name: 'SmartModuleWebACSystemChartContainer',
    components: {
        SmartModuleWebACModuleChartContainer,
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        selectedDeviceIdList: {
            type: Array,
            default: () => [],
        },
        allDeviceIdList: {
            type: Array,
            default: () => [],
        },
        langCd: {
            type: String,
            default: 'en',
        },
        condition: {
            type: Object,
            required: true,
        },
        timezoneId: {
            type: String,
            default: 'Asia/Seoul',
        },
    },
    setup(props) {
        const { t } = useI18n();
        const { locale } = useLocale();
        const ACModuleGraphData = ref([]);
        const xAxisCategories = ref([]);
        const yAxis = computed(() => getSmartModuleACSystemChartYAxis(t));
        const condition = computed(() => props.condition);
        const deviceIdList = computed(() => props.selectedDeviceIdList.length > 0 && props.selectedDeviceIdList[0] === SELECT_ALL
            ? props.allDeviceIdList
            : props.selectedDeviceIdList);
        const xAxisDateFormat = computed(() => props.condition.term_unit === GRAPH_TERM_UNIT.MINUTE
            ? COLON_HOUR_MIN_FORMAT
            : DateHelper.getLocalDateFormat({ locale, isTime: true, isSecond: false }));
        const setXAxisCategories = () => {
            const temporaryCategories = [];
            const startDate = isPeriod(condition.value.date) ? condition.value.date.start : condition.value.date;
            for (let i = 0; i < condition.value.term_value; i++) {
                const xAxisTimestamp = startDate.getTime() + i * ONE_MINUTE_VALUE_IN_MILLISECONDS;
                const xAxisDateStr = DateHelper.formatDate(new Date(xAxisTimestamp), xAxisDateFormat.value);
                temporaryCategories.push(xAxisDateStr);
            }
            xAxisCategories.value = temporaryCategories;
        };
        const convertTimestampToStringDate = (data) => {
            return data.map((item) => {
                return {
                    ...item,
                    colec_dt: DateHelper.getTimestampToTimeZoneDateFormat(item.colec_dt, props.timezoneId, xAxisDateFormat.value),
                };
            });
        };
        const removeDuplicatedPowerData = (data) => {
            const filteredData = data.reduce((previousData, current) => {
                if (previousData.findIndex(({ colec_dt }) => colec_dt === current.colec_dt) === -1) {
                    previousData.push(current);
                }
                return previousData;
            }, []);
            return filteredData;
        };
        const setACModuleList = () => {
            const legendList = ACModuleChartConfig.filterList.map((item) => {
                return deviceIdList.value.map((deviceId) => ({
                    id: deviceId,
                    type: item.type,
                    name: t(item.name) ?? item.altName,
                    altName: item.altName,
                    yAxis: item.yAxis,
                    unit: item.unit,
                    data: [],
                }));
            });
            if (isEmpty(props.data)) {
                return;
            }
            const convertData = convertTimestampToStringDate(props.data);
            const temporaryGraphData = [];
            legendList.forEach((ACModuleList) => {
                ACModuleList.forEach((ACModuleInfo) => {
                    let dataIdx = 0;
                    const filterData = removeDuplicatedPowerData(convertData.filter((item) => item.device_id === ACModuleInfo.id));
                    xAxisCategories.value.forEach((xAxisTime) => {
                        const isDataLastIndex = dataIdx === filterData.length - 1;
                        const isMatchedDataByDate = xAxisTime === filterData[dataIdx].colec_dt;
                        const moduleData = isMatchedDataByDate
                            ? Helper.nullTo(filterData[dataIdx][ACModuleInfo.yAxis], null)
                            : null;
                        ACModuleInfo?.data.push(moduleData ?? null);
                        if (!isDataLastIndex && isMatchedDataByDate)
                            dataIdx += 1;
                    });
                    temporaryGraphData.push(ACModuleInfo);
                });
            });
            ACModuleGraphData.value = temporaryGraphData;
        };
        const setACModuleGraphChart = () => {
            setXAxisCategories();
            setACModuleList();
        };
        watch(() => props.data, () => {
            setACModuleGraphChart();
        });
        return {
            yAxis,
            xAxisCategories,
            deviceIdList,
            ACModuleGraphData,
        };
    },
});
