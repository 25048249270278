import { defineComponent, ref } from 'vue';
import Select from 'primevue/select';
import { SELECT_OPTION_LIST_EXPANDED_STATUS } from '@hems/util/src/constant';
const defaultErrorInfo = {
    isError: false,
    errorMessage: '',
};
export default defineComponent({
    name: 'SelectNew',
    components: {
        Select,
    },
    props: {
        placeholder: {
            type: String,
            default: '',
        },
        selectedValue: {
            type: Object,
            default: null,
        },
        optionList: {
            type: Array,
            default: () => [],
        },
        errorInfo: {
            type: Object,
            default: () => defaultErrorInfo,
        },
        showDropdownIcon: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['change'],
    setup(props, { emit }) {
        const getSelectedOption = () => props.optionList.find((option) => option.value === props.selectedValue) ?? null;
        const selectedOption = ref(getSelectedOption());
        const optionListExpandedStatus = ref(SELECT_OPTION_LIST_EXPANDED_STATUS.HIDE);
        const changeOptionListExpandedStatus = (expandedStatus) => {
            optionListExpandedStatus.value = expandedStatus;
        };
        const onChangeSelectedOption = () => {
            emit('change', selectedOption.value);
        };
        return {
            selectedOption,
            optionListExpandedStatus,
            SELECT_OPTION_LIST_EXPANDED_STATUS,
            changeOptionListExpandedStatus,
            onChangeSelectedOption,
        };
    },
});
